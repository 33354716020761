import React,{useState} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import RestService from './../../RestService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({
   forceRefresh: true
   });
 

export default function ContactData(props) {
   const vals = props.values?props.values:[];
   const [submitDisabled,setsubmitDisabled] = useState(false);
   var min = 1;
   var max = 10;
   const val1 =  Math.round(min + (Math.random() * (max-min)));
   const val2 =  Math.round(min + Math.random() * (max - min));
   const sum  = val1 + val2;
    // form validation rules  
    const validationSchema = Yup.object().shape({
       
         name: Yup.string()
          .required('Name is required'), 
         company: Yup.string()
          .required('Company is required'),  
         email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'), 
         // captcha : Yup.string()
         //  .oneOf([Yup.ref('digit1')], 'Captcha must match')
         //  .required('Captcha is required'), 
         privacycheck: Yup.bool()
          .oneOf([true], 'Accept Ts & Cs is required')
  });
   

  // functions to build form returned by useForm() hook
  
  const { register, handleSubmit, reset, errors } = useForm({
   resolver: yupResolver(validationSchema)
});
  function onSubmit(fdata) {
      // display form data on success
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null));
      setsubmitDisabled(true);
      const data = JSON.stringify(fdata, null);
      RestService.updatecontactdata(function(res){  
         
             const response  =JSON.parse(res) ;   
             console.log(res) ;  
             setsubmitDisabled(false); 
             history.push('/thankyou');     
            //  if(response.data.data.metaData){
                
            //  }  
                           
       },data)
  }
   return (
      <>
         <div className="contact-right">
                  <div className="sec-head">
                    <h2 className="white">We’d Love<br />to Hear From You</h2>

                    <div className="contact-form-box">
                      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}  method="post">

                        <div className="form-group">
                          <label htmlFor="name"></label>
                          <input type="text"  id="name" name="name" placeholder="Name *"  ref={register} className={`form-control form-name ${errors.name ? 'is-invalid' : ''}`} />
                          <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="email"></label>
                          <input type="email"   id="email" name="email" placeholder="Email *"  ref={register} className={`form-control form-email ${errors.email ? 'is-invalid' : ''}`} />
                          <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="phone"></label>
                          <input type="tel" className="form-control form-phone" id="phone"  ref={register} name="phoneno" placeholder="Contact Number *"   />
                        </div>                        <div className="form-group">
                          <label htmlFor="company"></label>
                          <input type="text" ref={register} className={`form-control form-company ${errors.company ? 'is-invalid' : ''}`}   id="company" name="company" placeholder="company *"   />
                        </div>

                        <div className="form-group">
                          <textarea rows="5" cols="30" id="msg" name="msg" ref={register} placeholder="How Can we help you?" className="form-control form-msg" ></textarea>
                        </div>

                        <div className="col-sm-12 text-center">
                          <div className="fliper-cta">
                            <button className="fliper-cta-an" disabled={submitDisabled} type="submit">let's talk</button>
                            {submitDisabled?<CircularProgress color="secondary" />:''} 

                          </div>


                        </div>
                      </form>

                    </div>
                  </div>

                </div>
      </>

   )
}


