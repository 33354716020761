import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
export default class FixedNav extends React.Component {
    componentDidMount() {
        var $menu = $('#main-menu > li > a');
        $(document).mouseup(e => {
            if (!$menu.is(e.target) // if the target of the click isn't the container...
                &&
                $menu.has(e.target).length === 0) // ... nor a descendant of the container
            {
                $menu.siblings().removeClass('is-active');
            }
        });
        $menu.on('click', function () {
            $menu.siblings().removeClass('is-active');
            $(this).siblings().toggleClass('is-active');
        })
    }
    render() {
        return (
            <>
                <div className="fixed-navigation">
                    <div className="menu-container">
                        <div className="container flex-container flex-container-menu">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="lines">
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-box">
                            <ul id="main-menu">
                                <li class="dropdown"><Link to="#">design</Link>
                                    <ul class="sub-menu">
                                    <li><Link to="/work/design/">design</Link></li>
                                    <li><Link to="/presentation/">Presentations</Link></li>
                                    <li><Link to="/work/video/">Videos</Link></li>
                                    <li><Link to="/work/infographics/">Infographics</Link></li>
                                    <li><Link to="/work/digital-ads-banners/">Digital Graphics & Banners</Link></li>
                                    <li><Link to="/work/collaterals">Collaterals</Link></li>
                                    <li><Link to="/work/print-and-packaging">Social Media</Link></li>
                                    <li><Link to="/work/design/">Web</Link></li>
                                    <li><Link to="/work/mobile-designs/">Mobile</Link></li>
                                    </ul>
                                </li>
                                <li class="dropdown"><a href="#">technology</a>
                                    <ul class="sub-menu">
                                    <li><Link to="/">Maketing Automation</Link></li>
                                    <li><Link to="/">Website Development</Link></li>
                                    <li><Link to="/">App Development</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="">work</Link></li>
                                <li><Link to="">case studies</Link></li>
                                <li><Link to="/about/">about</Link></li>
                                <li><Link to="/contact/">contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="full-menu"> <Link className="menu-close" to="#"><i className="fa fa-times"></i></Link></div>
                </div>
            </>
        )
    }
}