import React, {Component,useEffect, useState }  from 'react';
import Header  from './../layout/Header';
import Footer  from './../layout/Footer';
import Hero    from './Hero';
import Feature from './Feature';
import Service from './Service';
import Slider  from './Slider';
import RestService from './../RestService';
import GlobalHelmet from './../GlobalHelmet';
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
const override = css` display: block; margin: 0 auto;  border-color: red;  position: fixed;  margin: auto;    top: 40%;    right: 40%;    z-index: 9999999;`;
  function Index() {
    const color = '#E93F33'; 
    const [loading, setLoading]                = useState(false);
    const[getHomedata,setgetHomedata]          = useState({});
    const [herodata, setherodata]              = useState(false);   
    const [metaData,setfeatureData]            = useState({}); 
    const [featureData,setmetaData]            = useState(false);
    const [serviceData,setserviceData]         = useState(false);
    const [productRImgurl,setproductRImgurl]   = useState(false);

    useEffect(() => {
        setLoading(true);
        RestService.gethomeList(function(res){  
          setLoading(false);
              const response  =JSON.parse(res) ;            
              if(response.data.data.metaData){
                setgetHomedata(response.data.data);
                console.log(getHomedata); 
              }  
                            
        })
      }, []);

       
    
         return (
        <> 
        {!getHomedata?  <GridLoader color={color} loading= {loading} css={override} size={70} />: 
        
        <>
      <GlobalHelmet metaValue={getHomedata.metaData?getHomedata.metaData:''}   />
       <section id="mainwrap"> 
        <Header     />
         <Hero herodata={getHomedata.heroData?getHomedata.heroData:''}  />
         <Slider slidevalue={getHomedata.testimonial?getHomedata.testimonial:''} />
         <Feature featurevalue={getHomedata.featureData?getHomedata.featureData:''}  />
         <Service serviceValue={getHomedata.serviceData?getHomedata.serviceData:''} />
        <Footer   />
        </section>
          
      </>
        
        
        
        
        }

         
        </>
    )
         
}


export default Index;