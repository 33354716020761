import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../GlobalHelmet';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import RestService from './../RestService';
import Loader from './../component/Loader';
import './PolicyData.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



export default function Index() {
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const [mediaData, setmediaData] = useState(false);

    useEffect(() => {
        setLoading(true);
        RestService.getprivacypolicy(function (res) {
            const response = JSON.parse(res);
            //  console.log(response);          
            if (response.data) {
                setmetaData(response.data.data.metaData);
                setmediaData(response.data.data.metaData.description);
            }
        });

    }, []);
    return (
        <>
            <GlobalHelmet metaValue={metaData ? metaData : ''} />

            <section id="mainwrap">
                <Header />
                <section className="innerpageTitle black">
                    <h2>PRIVACY POLICY</h2>
                </section>
                {!mediaData?
                <Loader />:
                <div class="tabs_content">
                    <div class="workflow">
                        <div class="production_page">
                            <div class="privacy-policy-page">
                                {mediaData ? ReactHtmlParser(mediaData) : ''}
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                </div>
            }
                <Footer />
            </section>

        </>

    )
}


