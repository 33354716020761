import React ,{Component,useState} from 'react';
import { Link } from 'react-router-dom';

export default function Footer(props ) {  
    
     
  return (
    <footer>
            <figure><img src={require('../images/logofooter.png').default} alt=""  width="255" height="67" /></figure>
            <article className="leftbox">
               <p>Tangence is a multi-disciplinary marketing agency that provides creative and marketing automation services to global enterprises at unprecedented efficiencies.</p>
               <p className="tallfree"> 1-408-323-9278 <br />
                  <Link to="mailto:info@tangence.com"  >info@tangence.com</Link>
               </p>
               <div>Copyright © 2021 Tangence Inc. All rights reserved.</div>
            </article>
            <nav>
               <ul>
                  <li> <Link to="/why-tangence">WHY TANGENCE</Link> 
                  </li>
                  <li>
                     <Link to="/work/design">WORK</Link> 
                     <ul>
                        <li><Link to="/work/design/">Design</Link></li>
                        <li><Link to="/work/marketing-automation/">Marketing Automation</Link></li>
                        <li><Link to="/work/production/">Production</Link></li>
                        <li><Link to="/work/technology/">Technology</Link></li>
                        <li><Link to="/work/search/">Search </Link></li>
                        <li><Link to="/work/content-marketing">Content Marketing</Link></li>
                     </ul>
                  </li>
                  <li><Link to="/about">ABOUT</Link></li>
                  <li><Link to="/contact">CONTACT</Link></li>
               </ul>
            </nav>
         </footer>
  )
}

 
