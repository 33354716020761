import React,{useState,useEffect}  from 'react';
import GlobalHelmet from './../GlobalHelmet';
import Header  from './../layout/Header';
import Footer  from './../layout/Footer' 
import Whydata from './WhyData';
import RestService from './../RestService';
import Loader from './../component/Loader';

export default function Index() {
    const [loading, setLoading] = useState(false);    
    const [metaData, setmetaData] = useState({});

    useEffect(() => {
        setLoading(true);
        RestService.getwhytangence(function(res){  
              const response  =JSON.parse(res) ; 
             localStorage.setItem('globaldata',JSON.stringify(response.data.data));
            //  console.log(response);          
              if(response.data){
                setmetaData(response.data.data.metaData); 
              }               
        })
      }, []);
    return (
        <>
         <GlobalHelmet metaValue={metaData ? metaData : ''} />
        <section id="mainwrap"> 
            <Header   /> 
            {!loading?<Loader />:
              <Whydata values={metaData} />  
            }
            <Footer   />
        </section>
        </>
    )
}
