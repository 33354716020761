import React, { Component }  from 'react';
import $ from 'jquery';

export default class Hero extends React.Component  { 
   componentDidMount() {
      $(".animatebox > div").mouseleave(function () {
         $(".back,.wining_back,.local_back,.ondemand_back").click();
     });
     $("#featured article").hover(
            function () {
               $("div", this).stop(true, true).animate({ bottom: "0px" }, 300);
            },
            function () {
               $("div", this).stop(true, true).animate({ bottom: "-68px" }, 300);
            }
      );

      $("div.summary").animate({ height: "120px", width: "120px", top: "230px", left: "250px" }, 200, function () {
         setTimeout(function () {
             $("#cube").css("display", "block");
         }, 200);
         $(".global div").animate({ height: "290px", width: "310px" }, 700, function () {
             $(".global p").fadeIn(300, function () {
                 $(".marketing div").animate({ height: "290px", width: "310px" }, function () {
                     $(".marketing p").fadeIn(300, function () {
                         $(".awardwin div").animate({ height: "290px", width: "310px" }, function () {
                             $(".awardwin p").fadeIn(300, function () {
                                 $(".local div").animate({ height: "290px", width: "310px" }, function () {
                                     $(".local p").fadeIn(300, function () {
                                         $(".local p").css("display", "inline-block");
                                     });
                                 });
                             });
                         });
                     });
                 });
             });
         });
     });

     $("#bannerbox .rightbox article p").hover(function () {
		let i = $("#bannerbox .rightbox article p").index(this);
		if (i == 0) {
			$("div.summary_detail").animate({
				height: "580px",
				width: "620px",
				left: '0px',
				top: '0px'
			}, function () {
				$("div.summary_detail .summary_detail_inner").fadeIn()
			})
		}
		if (i == 1) {
			$("div.local_detail").animate({
				height: "580px",
				width: "620px",
				left: '0px',
				top: '0px'
			}, function () {
				$("div.local_detail .local_detail_inner").fadeIn()
			})
		}
		if (i == 2) {
			$("div.wining_detail").animate({
				height: "580px",
				width: "620px",
				left: '0px',
				top: '0px'
			}, function () {
				$("div.wining_detail .wining_detail_inner").fadeIn()
			})
		}
		if (i == 3) {
			$("div.ondemand_detail").animate({
				height: "580px",
				width: "620px",
				left: '0px',
				top: '0px'
			}, function () {
				$("div.ondemand_detail .ondemand_detail_inner").fadeIn()
			})
		}
	}, function () {
		return false;
	});
	$("map area").hover(function () {
		var i = $("map area").index(this);
		if ($(".rightbox div").is(":animated")) {
			return false;
		} else {
			if (i == 0) {
				$("div.summary_detail").animate({
					height: "580px",
					width: "620px",
					left: '0px',
					top: '0px'
				}, function () {
					$("div.summary_detail .summary_detail_inner").fadeIn()
				})
			}
			if (i == 1) {
				$("div.local_detail").animate({
					height: "580px",
					width: "620px",
					left: '0px',
					top: '0px'
				}, function () {
					$("div.local_detail .local_detail_inner").fadeIn()
				})
			}
			if (i == 2) {
				$("div.wining_detail").animate({
					height: "580px",
					width: "620px",
					left: '0px',
					top: '0px'
				}, function () {
					$("div.wining_detail .wining_detail_inner").fadeIn()
				})
			}
			if (i == 3) {
				$("div.ondemand_detail").animate({
					height: "580px",
					width: "620px",
					left: '0px',
					top: '0px'
				}, function () {
					$("div.ondemand_detail .ondemand_detail_inner").fadeIn()
				})
			}
		}
	});
	$(".back").click(function (e) {
		e.preventDefault();
		$("div.summary_detail .summary_detail_inner").fadeOut(300, function () {
			$("div.summary_detail").animate({
				height: "0px",
				width: "0px",
				left: '50%',
				top: '50%'
			})
		})
	})
	$(".local_back").click(function (e) {
		e.preventDefault();
		$("div.local_detail .local_detail_inner").fadeOut(300, function () {
			$("div.local_detail").animate({
				height: "0px",
				width: "0px",
				left: '50%',
				top: '50%'
			})
		})
	})
	$(".wining_back").click(function (e) {
		e.preventDefault();
		$("div.wining_detail .wining_detail_inner").fadeOut(300, function () {
			$("div.wining_detail").animate({
				height: "0px",
				width: "0px",
				left: '50%',
				top: '50%'
			})
		})
	})
	$(".ondemand_back").click(function (e) {
		e.preventDefault();
		$("div.ondemand_detail .ondemand_detail_inner").fadeOut(300, function () {
			$("div.ondemand_detail").animate({
				height: "0px",
				width: "0px",
				left: '50%',
				top: '50%'
			})
		})
	})
     

   }
   render(){ 
  return (
   <section id="bannerbox">
   <article className="leftbox">
      <h1>A<br /> UNIQUE <br/>  MARKETING<br/> SERVICES<br/>
         MODEL<br clear="all" />
         <span>
            <a href="why-tangence.html">HOW TANGENCE REDEFINES YOUR CREATIVE & MARKETING AUTOMATION ROI</a>
            </span>
      </h1>
   </article>
   <section className="rightbox">
      <div className="animatebox">
         <div className="summary_detail">
            <div className="local_detail_inner summary_detail_inner">
               <div className="img"><img src={require('../images/icon4.png').default}  width="125" height="124" /></div>
               <div className="clr"></div>
               <h1>EFFICIENT EXECUTION</h1>
               <div className="clr"></div>
               <p>We are driven to achieve more with your marketing dollars. We combine the advantages of a global execution model that enables fast execution at unprecedented prices (as low as $35/hour).</p>
               <div className="clr"></div>
               <a href="" className="back_link back">BACK</a>
            </div>
         </div>
         <div className="local_detail">
            <div className="local_detail_inner">
               <div className="img"><img src={require('../images/icon3.png').default}  width="125" height="124" /></div>
               <div className="clr"></div>
               <h1>SCALE FAST</h1>
               <div className="clr"></div>
               <p>With a 150+ member in-house team and the ability to deploy many more partner resources, we can support your marketing needs at any scale. Use Tangence as an ongoing execution partner or bring us in for large one-off projects!</p>
               <div className="clr"></div>
               <a href="" className="back_link local_back">BACK</a>
            </div>
         </div>
         <div className="wining_detail">
            <div className="local_detail_inner wining_detail_inner">
               <div className="img"><img src={require('../images/icon2.png').default}  width="125" height="124" /></div>
               <div className="clr"></div>
               <h1>UNCOMPROMISING QUALITY</h1>
               <div className="clr"></div>
               <p>Over 15 years of working with global brands has ingrained the need for maintaining high quality standards in our teams. Whether it is design, or web programing, or marketing automation, we know quality is foremost on your mind, as it is on ours!</p>
               <div className="clr"></div>
               <a href="" className="back_link wining_back">BACK</a>
            </div>
         </div>
         <div className="ondemand_detail">
            <div className="local_detail_inner ondemand_detail_inner">
               <div className="img"><img src={require('../images/icon1.png').default}  width="125" height="124" /></div>
               <div className="clr"></div>
               <h1>Services-On-Demand</h1>
               <div className="clr"></div>
               <p>Our SOD approach is based on a predictable monthly spend and means you will always have the resources you need to deliver cost-effective, results-driven programs.</p>
               <div className="clr"></div>
               <a href="" className="back_link ondemand_back">BACK</a>
            </div>
         </div>
      </div>
      <div className="summary">
         <div id="cube">
            <img src={require('../images/icons_middle.jpg').default} alt="" width="120" height="120" border="0" usemap="#Map" />
            <map name="Map">
               <area shape="rect" coords="6,5,54,56" href="#" />
               <area shape="rect" coords="63,5,120,56" href="#" />
               <area shape="rect" coords="6,65,55,119" href="#" />
               <area shape="rect" coords="61,63,117,124" href="#" />
            </map>
         </div>
      </div>
      <article className="local">
         <div></div>
         <p>EFFICIENT<br/>
            EXECUTION
         </p>
      </article>
      <article className="global">
         <div ></div>
         <p>SCALE<br/> 
            FAST
         </p>
      </article>
      <article className="awardwin">
         <div></div>
         <p>UNCOMPROMISING<br />
            QUALITY
         </p>
      </article>
      <article className="marketing">
         <div></div>
         <p>  SERVICES<br/>
            ON-DEMAND
         </p>
      </article>
   </section>
</section>
     
  )
}
}

