import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Advantage(props) {
   const frData = props.frontData;
    return (
        <>
          <section className="advantages-sec common-sec grey-sec">
          <div className="inner-container-box">
            <div className="advntag-gradient-1 advntag-gradient"></div>
            <div className="advntag-gradient-2 advntag-gradient"></div>
            <div className="advntag-gradient-3 advntag-gradient"></div>
            <div className="shapes advntage-circle-1"></div>
            <div className="shapes advntage-circle-2"></div>
            <div className="shapes advntage-circle-3"></div>
            <div className="shapes advntage-circle-4"></div>
            <div className="shapes advntage-triangle-1">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                     <g id="Floor">
                        <polygon className="cls-3-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                     </g>
                  </g>
               </svg>
            </div>
            <div className="shapes advntage-triangle-2">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                     <g id="Floor">
                        <polygon className="cls-3-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                     </g>
                  </g>
               </svg>
            </div>
            <div className="shapes advntage-triangle-3">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                     <g id="Floor">
                        <polygon className="cls-3-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                     </g>
                  </g>
               </svg>
            </div>
            
            <div className="shapes advntage-rombus-1"></div>
            <div className="shapes advntage-rombus-2"></div>
            <div className="container">
               <div className="row">
                  <div className="col-xs-12 col-lg-12">
                     <div className="sec-head mb-100">
                        <h2>{frData.advheading?ReactHtmlParser(frData.advheading):''} </h2>
                     </div>
                  </div>
               </div>
               <div className="row mb-60 adv-row-1">
                  <div className="col-xs-12 col-md-6 order-low">
                     <div className="advantage-content-box">
                        <h3>{frData.designsheading?ReactHtmlParser(frData.designsheading):''}</h3>
                        <h4>{frData.designsubheading?ReactHtmlParser(frData.designsubheading):''}</h4>
                        <p>{frData.designsdes?ReactHtmlParser(frData.designsdes):''} </p>
                     </div>
                  </div>
                  <div className="col-xs-12 col-md-6 order-high">
                     <div className="advantage-img-box">
                        <img src={frData.designsimg?frData.designsimg:''} alt="" />
                     </div>
                  </div>
               </div>
               <div className="row mb-60 adv-row-2">
                  <div className="col-xs-12 col-md-6 order-high">
                     <div className="advantage-content-box">
                        <h3>{frData.partnersheading?ReactHtmlParser(frData.partnersheading):''}</h3>
                        <h4>{frData.partnersubheading?ReactHtmlParser(frData.partnersubheading):''}</h4>
                        <p>{frData.partnerdes?ReactHtmlParser(frData.partnerdes):''} </p>
                     </div>
                  </div>
                  <div className="col-xs-12 col-md-6 order-low">
                     <div className="advantage-img-box">
                        <img src={frData.partnersimg?frData.partnersimg:''} alt="" />
                     </div>
                  </div>
               </div>
               <div className="row adv-row-3">
                  <div className="col-xs-12 col-md-6 order-low">
                     <div className="advantage-content-box">
                     <h3>{frData.dramheading?ReactHtmlParser(frData.dramheading):''}</h3>
                        <h4>{frData.dramsubheading?ReactHtmlParser(frData.dramsubheading):''}</h4>
                        <p>{frData.dramdes?ReactHtmlParser(frData.dramdes):''} </p>
                     </div>
                  </div>
                  <div className="col-xs-12 col-md-6 order-high">
                     <div className="advantage-img-box">
                        <img src={frData.dramimg?frData.dramimg:''} alt="" />
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </section>  
        </>
    )
}
