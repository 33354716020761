import React,{useState} from 'react';
import './../index.css';
import './../App.css'; 
// import './../OldStyle.css';
// import './../Styleold.css';
import { useHistory,Link } from 'react-router-dom';
export default function Header(props ) {  
  
    return (
     <>     
      <header>
            <div className="logo">
              <Link to="/">
                <img src={require('../images/logo.png').default}     alt="" /> 
             </Link>
            </div>
            <div className="navigation">
               <div id="menu-trigger">
                  <i className="fa fa-bars" aria-hidden="true"></i>
               </div>
               <nav  className="nav-bar">
                  <ul className="menu1">
                     <li> <Link to="/why-tangence">WHY TANGENCE </Link>  </li>
                     <li><Link to="/work/design" className="">WORK</Link> </li>
                     <li className="sep-li">
                        our Story
                        <ul className="submenu1">
                           <li> <Link to="/about">ABOUT</Link></li>
                           <li> <a href="http://tangence.com/blog" >blog</a></li>
                           <li> <a href="http://tangence.com/b2bjobs">B2B Job Portal</a></li>
                        </ul>
                     </li>
                     <li> <Link to="/contact/">CONTACT</Link></li>
                  </ul>
               </nav>
            </div>
         </header>
     </>
  );
}

 