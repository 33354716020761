import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../../GlobalHelmet';
import Header from './../../layout/Header';
import Footer from './../../layout/Footer';
import Tabs from './../../component/Tablist';
import { Link, useParams } from 'react-router-dom';
import RestService from './../../RestService';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Loader from './../../component/Loader';
import NextPre from './../../component/NextPre';
import ScrollToTop from './../../component//ScrollToTop';

export default function TechDetails() {
    const userParams = useParams(); console.log(userParams);
    const projectslug = userParams.contentslug;
    const [techlist, settechlist] = useState(true);
    const [metaData, setmetaData] = useState(false);
    const [mediaData, setmediaData] = useState(false);
    const [param, setparam] = React.useState(false);
    if (userParams != param) {
        setparam(userParams);
    }

    useEffect(() => {
        RestService.getContentDetails(function (res) {
            const response = JSON.parse(res);
            let itemdata = '';
            if (response.data) {
                setmetaData(response.data.data.metaData);
                settechlist(response.data.data.searchlist);
                const projectlist = response.data.data.searchlist;
                if (projectlist) {
                    projectlist.map(item => {
                        if (item.mediatype == 1) {
                            itemdata += '  <video id="example_video_1" className="video-js vjs-default-skin" controls preload="none" width="1180" height="602"  poster=""  data-setup=""><source src="' + item.baseurl + item.media + '" type=\'video/mp4\' /></video>';
                        } else {
                            itemdata += '<img src="' + item.baseurl + item.media + '" />';
                        }
                    });
                }
                setmediaData(itemdata);
                // console.log(response.data.data.projectlist);  
            }
        }, projectslug);

    }, [param]);



    return (
        <>

            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <section id="mainwrap">
                <Header />
                <section className="innerpageTitle black">
                    <h2>Work / <span>CONTENT MARKETING</span></h2>
                </section>
                <Tabs value="content" />
                <NextPre backlink="/work/content-marketing/" nextlink={metaData.nextLink ? '/work/content-marketing/' + metaData.nextLink : ''} prelink={metaData.preLink ? '/work/content-marketing/' + metaData.preLink : '#'} />

                {!techlist ? <Loader /> :
                    <>



                        <div className="work_middle" id="topsec">
                            <div className="tabs_content">
                                <div className="workflow">
                                    <div className="production_page case_study_inner">
                                        <div className="top">
                                            <div><img src={metaData.image ? metaData.image : ''} /></div>
                                            {metaData.paragraph ? ReactHtmlParser(metaData.paragraph) : ''}
                                            <p>&nbsp;</p>
                                            <div>
                                                {mediaData ? ReactHtmlParser(mediaData) : ''}
                                            </div>
                                        </div>
                                        <div className="clr"></div>
                                        <ScrollToTop />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                }
                <Footer />
            </section>

        </>
    )
}
