import React from 'react';
import { BrowserRouter as Router,Link } from 'react-router-dom'; 


export default function NextPre(props) {  
   console.log(props); 
   let prelink  = props.prelink?props.prelink:'#';
   let nextlink = props.nextlink?props.nextlink:'#';
   let backlink = props.backlink?props.backlink:'#';
    return (
        <>
        <div className="prev_next">
         <div className="work_padding">
            <Link to={backlink} className="backin">BACK</Link>
            <Link to={nextlink} className={  nextlink != '#'?'next_case_study':'next_case_study in-active' } >NEXT</Link> 
            <Link to={prelink}   className={ prelink != '#'? "next_case_study prev":"next_case_study prev in-active" }>PREVIOUS</Link> 
         </div>
      </div>
      <div className="clr"></div>
            
        </>
    )
}
