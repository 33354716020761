import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useHistory,Link } from 'react-router-dom';

export default function Discover(props) {
   const frData = props.frontData;
    return (
        <>
          <section className="onboard-sec grey-sec common-sec">
          <div className="inner-container-box">
            <div className="onboard-gradient-1 onboard-gradient"></div>
            <div className="onboard-gradient-2 onboard-gradient"></div>
            <div className="shapes onboard-new-triangle-2">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="cls-cs-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="shapes onboard-new-triangle-3">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="what-new-9-3" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>


            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="sec-head mb-60">
                        <h2>{frData.onboardingheading?ReactHtmlParser(frData.onboardingheading):''}</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6 col-md-6">
                     <div className="on-board-grid">
                        <div className="on-board-col on-board-left">
                           <h2>1.</h2>
                        </div>
                        <div className="on-board-col on-board-right">
                           <div className="on-board-img-box">
                              <div className="on-board-img">
                                 <div className="on-board-img-hover">
                                    <img src={require('../../images/icons/icon-1.png').default} alt="" />
                                 </div>
                              </div>
                              <div className="on-board-cntnt">
                                 <h5>{frData.onboardingdiscovery?ReactHtmlParser(frData.onboardingdiscovery):''}</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                     <div className="on-board-grid">
                        <div className="on-board-col on-board-left">
                           <h2>2.</h2>
                        </div>
                        <div className="on-board-col on-board-right">
                           <div className="on-board-img-box">
                              <div className="on-board-img">
                                 <div className="on-board-img-hover">
                                    <img src={require('../../images/icons/icon-2.png').default} alt="" />
                                 </div>
                              </div>
                              <div className="on-board-cntnt">
                                 <h5>{frData.onboardinginit?ReactHtmlParser(frData.onboardinginit):''}</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-12">
                     <div className="fliper-cta-box text-center mt-60">
                        <div className="fliper-cta">
                           <Link to="/contact/" className="fliper-cta-an">Start now</Link>    
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </section> 


         
        </>
    )
}
