import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function VideoProduction() {
    console.log(useParams());
    let userParams = useParams();
    const blog_id = userParams.blog_id ? userParams.blog_id : '';
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const [bloglist, setbloglist] = useState(false);
    const [blogdetails, setblogdetails] = useState({});
    const [blogid, setblogid] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true);
        setblogid(blog_id);
        RestService.getBlogData(function (res) {
            const response = JSON.parse(res);
            //  console.log(response);          
            if (response.data) {
                setmetaData(response.data.data.metaData);
                setbloglist(response.data.data.bloglist);
                setblogdetails(response.data.data.blogDetails);

            }
            setLoading(false);
        }, blog_id);

    }, [blog_id]);
    return (
        <>
            {loading ? <Loader /> : ''}
            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <div className="main-container">
                <Header />
                <div className="inner-pages">
                    <div className="inner-container-box">
                        <div className="blog-page-grad blog-page-grad-1"></div>
                        <div className="blog-page-grad blog-page-grad-2"></div>
                        <div className="shapes blog-circle-1"></div>
                        <div className="shapes blog-rombus-1"></div>
                        <div className="shapes blog-triangle-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Floor">
                                        <polygon className="cls-blog-svg-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="shapes blog-triangle-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Floor">
                                        <polygon className="cls-blog-svg-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <section className="blog-inner-main sec-inner-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="sec-head sec-head-2">
                                            <h2>blog</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="main-blog-box">
                                            <h4>{blogdetails.name ? ReactHtmlParser(blogdetails.name) : ''}</h4>
                                            <div className="main-blog-img">
                                                <img src={blogdetails.image ? blogdetails.image : ''} alt="" />
                                            </div>
                                            <div className="main-blog-cntnt">
                                                <div className="main-blog-cntnt-inner">
                                                    {blogdetails.description ? ReactHtmlParser(blogdetails.description) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="side-blog-col">
                                            <div className="side-blog-col-box">
                                                {bloglist ?
                                                    bloglist.map((item, i) => (

                                                        <div className="side-blog-box" key={item.slug}>
                                                            <div className="side-blog-img">
                                                                <Link to={'/blog/' + item.slug} ><img src={item.image ? item.image : ''} alt="" /></Link>
                                                            </div>
                                                            <div className="side-blog-cntnt">
                                                                <h6><Link to={'/blog/' + item.slug}>{item.name ? ReactHtmlParser(item.name) : ''}</Link></h6>
                                                            </div>
                                                        </div>

                                                    ))
                                                    : ''}


                                            </div>
                                            <div className="load-more text-center">
                                                <a href="#" target="_blank" >more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
