import React from 'react';
import { Link } from 'react-router-dom';

export default function Tablist(props) { 
   let value = props.value;
    return (
        <>
        <div className="work_section_nav">
                  <div className="work_padding">
                     <ul>
                        <li className={value=='design'?'active':''} ><Link to="/work/design">DESIGN</Link></li>
                        <li className={value=='marketting'?'active':''} ><Link to="/work/marketing-automation/">Marketing Automation</Link></li>
                        <li className={value=='production'?'active':''} ><Link to="/work/production/">PRODUCTION</Link></li>
                        <li className={value=='technology'?'active':''}><Link to="/work/technology">TECHNOLOGY</Link></li>
                        <li className={value=='search'?'active':''}><Link to="/work/search/">SEARCH</Link></li>
                        <li className={value=='content'?'active':''}><Link to="/work/content-marketing/">CONTENT MARKETING</Link></li>
                     </ul>
                  </div>
               </div>
            
        </>
    )
}
