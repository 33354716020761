import React from 'react'

export default function Feature() {
  return (
     <>
     <section id="featured">
            <header>FEATURED WORK <a href="/work/design.html">MORE <span>»</span> </a></header>
            <section className="leftbox">
               <article>
                  <img src={require('../images/sap.jpg').default} width="560" height="302" alt="" /> 
                  <div> 
                     <span>Corporate Video</span>
                     <a href="/work/view-projects/video/sap.html">VIEW WORK »</a>
                  </div>
               </article>
               <article>
                  <img src={require('../images/intel.jpg').default}  alt=""  width="560" height="601" /> 
                  <div> 
                     <span>Brochure</span>
                     <a href="">VIEW WORK »</a>
                  </div>
               </article>
            </section>
            <section className="rightbox">
               <article className="learn-develop">
                  <img  src={require('../images/lean-development.jpg').default} alt=""  width="620" height="253" /> 
                  <div> 
                     <span>Scenario Based Learning</span>
                     <a href="/"> 
                     VIEW WORK »
                     </a>
                  </div>
               </article>
               <section className="lastsection">
                  <article>
                     <img src={require('../images/evans.jpg').default} alt="" width="301" height="300" />
                     <div> 
                        <span>Collateral Design </span>
                        <a href="/"> 
                        VIEW WORK »
                        </a>
                     </div>
                  </article>
                  <article>
                     <img src={require('../images/buisoft.jpg').default} alt=""  width="320" height="300" />
                     <div> 
                        <span>Powerpoint Design</span>
                        <a href="/">VIEW WORK »</a>
                     </div>
                  </article>
               </section>
               <article>
                  <img  src={require('../images/actiance.jpg').default} alt=""  width="620" height="352" /> 
                  <div> 
                     <span>Website Design and Development</span>
                     <a href="/"> 
                     VIEW WORK »
                     </a>
                  </div>
               </article>
            </section>
         </section>
     </>
  )
}
