import React from 'react'
import { css } from "@emotion/core";
import GridLoader from "react-spinners/PuffLoader";
const override = css` display: block; margin: 0 auto;  border-color: red;  position: fixed;  margin: auto;    top: 40%;    right: 40%;    z-index: 9999999;`;
const color = '#E93F33'; 
export default function Loader() {
    return (
        <>
           <GridLoader color={color}   css={override} size={100} /> 
        </>
    )
}
