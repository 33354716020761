import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet'; 
import RestService from './../../RestService'; 

export default function Index() {


   const [loading, setLoading] = useState(false);
   const [metaData, setmetaData] = useState({});
 


   return (

      <div className="main-container">
         <GlobalHelmet metaValue={metaData ? metaData : ''} />
         <Header />
         <div className="inner-pages">
            <div className="blog-page-grad contact-page-grad-1"></div>
            <div className="shapes contact-page-rombus-1"></div>
            <section className="contact-us-page">
               <div className="container">
                  <div className="row">

                     <div className="col-12">

                        <div className="contact-right text-center">
                           <div className="sec-head ">
                              <h2 className="white text-center">Thank you for your inquiry.</h2>
                              <p className="WHITECOLOR white">We will get back to you very soon.</p>
                           </div>

                        </div>

                     </div>
                  </div>
               </div>

            </section>
         </div>
         <Footer />
      </div>

   )
}
