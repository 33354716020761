import React from 'react'; 
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider";

function SampleNextArrow(props) {
   const { className, style, onClick } = props;
   return (
      <img className="next-img" src={require('../images/btn-next.jpg').default}  width="37" height="68" onClick={onClick} />
   );
 }
 
 function SamplePrevArrow(props) {
   const { className, style, onClick } = props;
   return (
      <img className="pre-img" src={require('../images/btn-prev.jpg').default}  width="37" height="68" onClick={onClick} />
   );
 }

export default function Slider(props) {
   var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    console.log(props);
    return (
      <div className="slidingbox" >
         {!props.slidevalue? '' :
         <Sliders {...settings} >
              {props.slidevalue.map((slideval) => (
              <div>
                 <div className="slide-content">{slideval.description}<span></span> {slideval.author}</div>
                 </div> 
               ))}
              
        </Sliders>

}
          
          
   </div>
    )
}
