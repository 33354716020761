import React from 'react';
import $ from 'jquery';
import Slider from "react-slick";

function SampleNextArrow(props) {
   const { className, style, onClick } = props;
   return (
      <button className="slide-arrow next-arrow fa-chevron-right" onClick={onClick}></button>
   );
}

function SamplePrevArrow(props) {
   const { className, style, onClick } = props;
   return (
      <button className="slide-arrow prev-arrow fa-chevron-left" onClick={onClick}></button>
   );
}

export default class Whatisnew extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         isShow: true,
      };
   }
   componentDidMount() {

   }
   render() {
      const settings = {
         autoplay: false,
         speed: 1000,
         arrows: true,
         slidesToShow: 3,
         slidesToScroll: 1,
         dots: false,
         centerMode: false,
         infinite: true,
         focusOnSelect: true,
         cssEase: 'linear',
         nextArrow: <SampleNextArrow />,
         prevArrow: <SamplePrevArrow />,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
               }
            }
         ]
      };
      return (
         <>
            <section className="whats-new common-sec">
               <div className="inner-container-box">
               <div className="service-gradient-new hm-blog-gradient-new-2"></div>
               <div className="star-shape onboard-star"></div>
<div className="shapes hm-blog-circle-1"></div>
<div className="shapes hm-blog-triangle-1">
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
      <g id="Layer_2" data-name="Layer 2">
         <g id="Floor">
            <polygon className="cls-cs-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
         </g>
      </g>
   </svg>
</div> 
                  <div className="shapes what-new-triangle-1">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="what-new-9-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="shapes what-new-triangle-2">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="what-new-9-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="shapes what-new-triangle-3">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="what-new-9-3" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12">
                           <div className="sec-head mb-60">
                              <h2>what’s new</h2>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="container-fluid">
                     <div className="whats-new-box">
                        <div className="h-blog-grid">
                           <div className="h-blog-col h-blog-col-right">
                              <div className="h-blog-slider">
                                 <div >



                                    {this.props.whatsnew ? <>

                                       <Slider {...settings} className="full-blog-slider">
                                          {this.props.whatsnew.map((slideval, i) => (
                                             <div className="slick-slideshow__slide" >
                                                <div className="blog-box">
                                                   <div className="blog-img"><img key={i} src={slideval.image} alt="" />
                                                   </div>
                                                   <div className="blog-cntnt">
                                                      <h6 key={i}>{slideval.text}</h6>
                                                   </div>
                                                </div>
                                             </div>
                                          ))}
                                       </Slider>
                                    </> : ''}







                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

         </>
      )
   }
}
