import {React,useState} from 'react';
import Router from  './router/index';
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;




 

function App() {
  
  let [loading, setLoading] = useState(true);
  let color = '#E93F33'; 
  const Loadscript= ()=>{
    const script_1 = document.createElement('script');
    script_1.src='https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
    script_1.type = 'text/javascript';
    document.body.appendChild(script_1);
  }
  return ( 
    <> 
          <Router /> 
          </>
  )
}

export default App;
