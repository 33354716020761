import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';

import AboutData from './AboutData';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function Index() {

    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const [mediaData, setmediaData] = useState(false);

    useEffect(() => {
        setLoading(true);
        RestService.getaboutusdata(function (res) {
            const response = JSON.parse(res);
            //  console.log(response);          
            if (response.data) {
                setmetaData(response.data.data.metaData);
                let meddata = {heading :response.data.data.metaData.heading ,paragraph :response.data.data.metaData.paragraph,paragraph2 : response.data.data.metaData.paragraph2 }
                setmediaData(response.data.data.metaData);
            }
        });

    }, []);


  return (

    <>
      <GlobalHelmet metaValue={metaData ? metaData : ''} />
      <div className="main-container">
      <Header />
      <AboutData heading={mediaData.heading?mediaData.heading:''} paragraph={mediaData.paragraph?mediaData.paragraph:''} paragraph2={mediaData.paragraph?mediaData.paragraph2:''}  foundation={mediaData}/>       
      <Footer />
      </div>
    </>

  )
}
