import React, { Component } from 'react'
import ReactDom from "react-dom";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './../home/index';
import WhyTangence from './../why-tangence/Index';
//import About from './../about/Index';
//import Contact from './../contact/Index';
//import Thankyou from './../contact/Thankyou';
import Privacy from './../privacypolicy/Index';
//import Design from './../work/design/Index';
import Viewprojects from './../work/design/Viewprojects';
import ProjectDetails from './../work/design/DesignDetails';
import MarketingC from './../marketing/Index';
import ProductionC from './../production/Index';
import TechnologyC from './../work/technology/Index';
import TechDetails from './../work/technology/TechDetails';
import ViewTechnology from './../work/technology/ViewTechnology';
import SearchC from './../work/search/Index';
import ViewSearch from './../work/search/SearchDetails';

import MarketC from './../work/content/Index';
import ViewMarket from './../work/content/ContentDetails';

import UpdatedHome from './../updated/home/index';
import UpdatedHome2 from './../updated/home/Index2';
import UpdatedHome3 from './../updated/home/Index3';

import Contact from './../updated/contact/Index';
import Thankyou from './../updated/contact/Thankyou';
import About from './../updated/about/Index';
import Presentation from './../updated/presentation/Index';
import Design from './../updated/work/Design';
import VideoProduction from './../updated/work/VideoProduction';
import DigitalAdsBanners from './../updated/work/DigitalAdsBanners';
import Printpackaging from './../updated/work/Printpackaging';
import Infographics from './../updated/work/Infographics';
import MobileDesigns from './../updated/work/MobileDesigns';
import Blog from './../updated/blog/Index';

export default class MyRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_system_error: true
        }
    }
    //marketing-automation
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    {/* <Route path="/" component={Home} exact /> */}

                    <Route path="/" component={UpdatedHome} exact />
                    <Route path="/home2" component={UpdatedHome2} exact />
                    <Route path="/home3" component={UpdatedHome3} exact />
                    <Route exact path="/why-tangence/" component={WhyTangence} />
                    <Route exact path="/about/" component={About} />
                    <Route exact path="/contact/" component={Contact} />
                    <Route exact path="/thankyou/" component={Thankyou} />
                    <Route exact path="/privacy-policy/" component={Privacy} />
                    <Route exact path="/work/design" component={Design} />


                    <Route exact path="/presentation/" component={Presentation} />
                    <Route exact path="/work/video/" component={VideoProduction} />
                    <Route exact path="/work/digital-ads-banners/" component={DigitalAdsBanners} />
                    <Route exact path="/work/print-and-packaging/" component={Printpackaging} />
                    <Route exact path="/work/infographics/" component={Infographics} />
                    <Route exact path="/work/mobile-designs/" component={MobileDesigns} />
                    <Route exact path="/blog/" component={Blog} />
                    <Route exact path="/blog/:blog_id" component={Blog} />


                    <Route exact path="/work/view-projects/:projectslug" component={Viewprojects} />
                    <Route exact path="/work/view-projects/:projectslug/:designslug" component={ProjectDetails} />
                    <Route exact path="/work/marketing-automation/" component={MarketingC} />
                    <Route exact path="/work/production/" component={ProductionC} />
                    <Route exact path="/work/technology/" component={TechnologyC} />
                    <Route exact path="/work/development/:techslug" component={ViewTechnology} />
                    <Route exact path="/work/development/:techslug/:pageslug" component={TechDetails} />


                    <Route exact path="/work/search/" component={SearchC} />
                    <Route exact path="/work/search/:searchslug" component={ViewSearch} />
                    <Route exact path="/work/content-marketing/" component={MarketC} />
                    <Route exact path="/work/content-marketing/:contentslug" component={ViewMarket} />





                    <Route exact path="*" component={Home} />

                </Switch>
            </BrowserRouter>
        )
    }
}



