import React from 'react';
import axios from 'axios';
import { Callbacks } from 'jquery';

const TokenStr = '123456789'; // process.env.tokenStr; 
const Baseurl = 'https://tangence-2021.tangence.com/API/index.php/'; //process.env.baseurl;
const Headers = { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` };
// console.log(process.env);
var RestService = {

  getworkList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist`, method: "GET", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getTechList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/technologylist`, method: "GET", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getTechDataList(callback, projectslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/techDatalist`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { slug: projectslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getTechDetails(callback, projectslug, pageslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/techdetails`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { techslug: projectslug, pageslug: pageslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getPorjectList(callback, projectslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/projectlist`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { slug: projectslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getPorjectDetails(callback, projectslug, pageslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/projectdetails`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { projectslug: projectslug, pageslug: pageslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getmarketList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/getmarketList`, method: "GET", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },

  getproductionList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/getproductionData`, method: "GET", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },


  getSearchList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/searchlist`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getSearchDetails(callback, projectslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/searchdetails`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { slug: projectslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },


  getcontentList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/contentlist`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getContentDetails(callback, projectslug) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/contentdetails`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` },
      params: { slug: projectslug }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },

  getBlogData(callback, blog_id) {
    let self = this;
    axios.request({
      url: `${Baseurl}blog/index?blog_id=${blog_id}`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getprivacypolicy(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/privacypolicy`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getaboutusdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/aboutus`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getpresentationdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/presentation`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getwebDesigndata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/webdesign`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getVideoProductiondata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/videoproduction`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getDigitalAdsBannersdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/digitalAdsBanners`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getPrintpackagingdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/printpackaging`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getInfographicsdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/infographics`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getMobileDesignsdata(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/mobileDesigns`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getwhytangence(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/whytangence`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  getcontact(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/contact`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },
  updatecontactdata(callback, formdata) {
    let self = this;
    axios.request({
      url: `${Baseurl}Getworklist/submitDetails`, method: "POST", headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}` }
      , params: { formdata }
    })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
  },

  gethomeList(callback) {
    let self = this;
    axios.request({
      url: `${Baseurl}Gethomelist`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json', "Authorization": `Basic ${TokenStr}`
      }
    })
      .then(function (response) {

        callback(JSON.stringify(response));

      })


  }


}

export default RestService;
