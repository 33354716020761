import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';

import ContactData from './ContactData';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function Index() {


  const [loading, setLoading] = useState(false);
  const [metaData, setmetaData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true);
    RestService.getcontact(function (res) {
      const response = JSON.parse(res);
      localStorage.setItem('globaldata', JSON.stringify(response.data.data));
      //  console.log(response);          
      if (response.data) {
        setmetaData(response.data.data.metaData);
      }
    })
  }, []);


  return (

    <div className="main-container">
      <GlobalHelmet metaValue={metaData ? metaData : ''} />
      <Header />
      <div className="inner-pages">
        <div className="inner-container-box">

          <div className="blog-page-grad contact-page-grad-1"></div>
          <div className="shapes contact-page-rombus-1"></div>
          <section className="contact-us-page">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-12">
                  <div className="contact-left">
                    <div className="contact-box">
                      <div className="contact-box-img">
                        <img src={require('../../images/resources/contact-img-1.png').default} alt="" />
                      </div>

                      <div className="contact-box-cntnt">
                        <h4>General Inquiries</h4>
                        <h5>info@tangence.com</h5>
                      </div>

                      <div className="contact-box-cntnt">
                        <h4>Looking for Change</h4>
                        <p>We are always looking for talented individuals</p>
                        <h6>careers@tangence.com</h6>

                      </div>



                    </div>
                  </div>



                </div>
                <div className="col-lg-5 col-md-5 col-12">

                  <ContactData />

                </div>
              </div>
            </div>

          </section>
        </div>
      </div>
      <Footer />
    </div>

  )
}
