import React from 'react'

export default function Oucclients(props) {
   const globalCmpny = props.globalCmpny ? props.globalCmpny : [];


   return (
      <>
         <section className="our-client common-sec">
            <div className="inner-container-box">

               
               <div className="shapes our-client-triangle-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                     <g id="Layer_2" data-name="Layer 2">
                        <g id="Floor">
                           <polygon className="cls-2-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                        </g>
                     </g>
                  </svg>
               </div>
               <div className="shapes our-client-triangle-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                     <g id="Layer_2" data-name="Layer 2">
                        <g id="Floor">
                           <polygon className="cls-2-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                        </g>
                     </g>
                  </svg>
               </div>
              
               <div className="container">
                  <div className="row">
                     <div className="col-xs-12 col-lg-12">
                        <div className="sec-head">
                           <h2><span>Used by Global Enterprise<br />
                           & Leading Growth Companies</span>
                           </h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xs-12 col-lg-12">
                        <div className="client-container">

                           {globalCmpny ? <>


                              {globalCmpny.map((slideval, i) => (
                                 <div className="client-box" key={i}>
                                    <div className="client-img">
                                       <img src={slideval.image} alt="" />
                                    </div>
                                 </div>
                              ))}
                           </> : ''}



                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}
