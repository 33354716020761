import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../../GlobalHelmet';
import Header from './../../layout/Header';
import Footer from './../../layout/Footer';
import Tabs from './../../component/Tablist';
import { Link, useParams } from 'react-router-dom';
import Boxlist from './../../component/Box';
import RestService from './../../RestService';
import Loader from './../../component/Loader';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Index() {
    const userParams = useParams(); console.log(userParams);
    const projectslug = userParams.techslug;
    const [techlist, settechlist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});

    useEffect(() => {
        setLoading(true);
        RestService.getSearchList(function (res) {
            const response = JSON.parse(res);

            if (response.data) {
                setmetaData(response.data.data.metaData);
                settechlist(response.data.data.techlist);
                // console.log(response.data.data.projectlist);  
            }
        }, projectslug);

    }, []);
    return (
        <>

            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <section id="mainwrap">
                <Header />
                <section className="innerpageTitle black">
                    <h2>Work / <span>Design</span></h2>
                </section>
                <Tabs value="search" />
                <section id="work_section">
                {!techlist ? <Loader /> :
                    <>
                        <div className="work_middle">
                            <div className="tabs_content">
                                <div className="workflow">
                                    <h2> {metaData.heading ? ReactHtmlParser(metaData.heading) : ''} </h2>

                                    <div className="clr"></div>
                                    <p>{metaData.paragraph ? metaData.paragraph : ''}</p>


                                </div>
                                <div className="clr"></div>

                                {techlist ? techlist.map(item => (
                                    <Boxlist value={item} pagename={'/work/search/'} sampleTxt="Case Study »" />
                                )) : ''}

                                <div className="midbutton2 development">
                                    <Link to="/contact"> LET'S TALK BUSINESS. CONTACT US »</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }
                </section>
                <Footer />
            </section>

        </>
    )
}