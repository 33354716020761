import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function WhyData(props) {
   const vals = props.values?props.values:[];
   return (
      <section className="advantageSec">
   <ul>
      <li className="first">
         <div className="inner">
            <h2>{vals.heading?ReactHtmlParser(vals.heading):''} </h2>
            <p>{vals.description?vals.description:''}</p>
         </div>
      </li>
      <li>
         <img src={require('../images/advantage-list-2.jpg').default} alt="" width="100%" height="590" />
      </li>
      <li className="basic">
         <div className="inner">
            <div className="no">NO. <span>1</span></div>
            <div className="midpart">
               <h2>{vals.heading1?ReactHtmlParser(vals.heading1):''} </h2>
               <p>{vals.paragraph1?ReactHtmlParser(vals.paragraph1):''} </p>
            </div>
         </div>
      </li>
      <li className="basic yellowbox">
         <div className="inner">
            <div className="no">NO. <span>2</span></div>
            <div className="midpart">
               <h2>{vals.heading2?ReactHtmlParser(vals.heading2):''} </h2>
               <p>{vals.paragraph2?ReactHtmlParser(vals.paragraph2):''} </p>
            </div>
         </div>
      </li>
      <li className="adv3">
         <div className="inner">
            <div className="no">NO. <span>3</span></div>
            <div className="txt">
               <h2>{vals.heading3?ReactHtmlParser(vals.heading3):''} </h2>
               <p>{vals.paragraph3?ReactHtmlParser(vals.paragraph3):''} </p>
            </div>
         </div>
      </li>
      <li className="basic adv4">
         <div className="inner">
            <div className="no">NO. <span>4</span></div>
            <div className="midpart">
            <h2>{vals.heading4?ReactHtmlParser(vals.heading4):''} </h2>
               <p>{vals.paragraph4?ReactHtmlParser(vals.paragraph4):''} </p>
            </div>
         </div>
      </li>
      <li className="basic">
         <div className="inner">
            <div className="no">NO. <span>5</span></div>
            <div className="midpart">
            <h2>{vals.heading5?ReactHtmlParser(vals.heading5):''} </h2>
               <p>{vals.paragraph5?ReactHtmlParser(vals.paragraph5):''} </p>
            </div>
         </div>
      </li>
   </ul>
   <div className="clr"></div>
</section>
   )
}
