import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import RestService from './../../RestService';
import Slider from './WorkSlider';
import Loader from './../../component/Loader';

export default function Index() {

  const [loading, setLoading] = useState(false);
  const [metaData, setmetaData] = useState({});
  const [frontData, setfrontData] = useState(false);
  const [creativeData, setcreativeData] = useState(false);
  const [presentationData, setpresentationData] = useState(false);
  const [sliderData, setsliderData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true);
    RestService.getpresentationdata(function (res) {
      const response = JSON.parse(res);
      //  console.log(response);          
      if (response.data) {
        setmetaData(response.data.data.metaData);
        setfrontData(response.data.data.frontData);
        setcreativeData(response.data.data.creativeData);
        setpresentationData(response.data.data.presentationData);
        setsliderData(response.data.data.sliderData);
        setmetaData(response.data.data.metaData);

      }
    });

  }, []);


  return (

    <>
      <GlobalHelmet metaValue={metaData ? metaData : ''} />
      <div className="main-container">
        <Header />

        <div className="inner-pages white">
          <section className="bread-crumb">
            <div className="container">
              <div className="bread-cum">

                <ol className="cd-breadcrumb">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/work/design/">Design</Link></li>
                  <li><Link to="/presentation/">Presentation</Link></li>
                </ol>
              </div>
            </div>
          </section>

          <section className="Presentnt-main sec-Presentnt-1 common-sec">
            <div className="inner-container-box">
              <div className="star-Presentnt-1 star-Presentnt"></div>
              <div className="shapes Presentnt-triangle-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="cls-1-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-12">
                    <div className="presentation-img-box presentation-img-box-1">
                      <img src={frontData.leftimg ? frontData.leftimg : ''} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-12">
                    <div className="presentation-cntnt-box presentation-cntnt-box-1">
                      <div className="sec-head mb-40">
                        <h2>{frontData.heading ? ReactHtmlParser(frontData.heading) : ''}</h2>
                      </div>
                      <p>{frontData.description ? ReactHtmlParser(frontData.description) : ''}</p>
                      <div className="fliper-cta-box">
                        <div className="fliper-cta">
                          <Link to="/contact/" className="fliper-cta-an">get started</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="Presentnt-main sec-Presentnt-2 common-sec">
            <div className="inner-container-box">
              <div className="sec-Presentnt-2-gradient-1 sec-gradient"></div>
              <div className="sec-Presentnt-2-gradient-2 sec-gradient"></div>
              <div className="sec-Presentnt-2-gradient-3 sec-gradient"></div>
              <div className="shapes Presentnt-2-rombus-1"></div>
              <div className="shapes Presentnt-2-circle-1"></div>
              <div className="shapes Presentnt-2-circle-2"></div>
              <div className="shapes Presentnt-2-star-1"></div>
              <div className="shapes Presentnt-2-star-2"></div>
              <div className="shapes Presentnt-2-triangle-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="cls-1-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="shapes Presentnt-2-triangle-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="cls-1-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="presentation-preview">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="presentation-cntnt-box presentation-cntnt-box-2">
                        <div className="sec-head mb-150">
                          <h2>{frontData.superaffordable ? ReactHtmlParser(frontData.superaffordable) : ''}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">

                      <div className="presentation-row">
                        <div className="presentation-col presentation-col-1">
                          <div className="presentnt-col-box">
                            <div className="presentnt-col-box-top">
                              <h3>01</h3>
                            </div>
                            <h5>{frontData.newheading ? ReactHtmlParser(frontData.newheading) : ''}</h5>
                            <p>{frontData.newdescription ? ReactHtmlParser(frontData.newdescription) : ''}</p>
                          </div>
                        </div>
                        <div className="presentation-col presentation-col-2">
                          <div className="presentnt-col-box">
                            <div className="presentnt-col-box-top">
                              <h3>02</h3>
                            </div>
                            <h5>{frontData.tiredheading ? ReactHtmlParser(frontData.tiredheading) : ''}</h5>
                            <p>{frontData.tireddescription ? ReactHtmlParser(frontData.tireddescription) : ''}</p>
                          </div>
                        </div>
                        <div className="presentation-col presentation-col-3">
                          <div className="presentnt-col-box">
                            <div className="presentnt-col-box-top">
                              <h3>03</h3>
                            </div>
                            <h5>{frontData.reusableheading ? ReactHtmlParser(frontData.reusableheading) : ''}</h5>
                            <p>{frontData.reusabledes ? ReactHtmlParser(frontData.reusabledes) : ''}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="presentation-delivered">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="presentation-cntnt-box presentation-cntnt-box-2">
                        <div className="sec-head mb-40">
                          <h2>{frontData.presDeliveryHeading ? ReactHtmlParser(frontData.presDeliveryHeading) : ''}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                    {presentationData ?
                      presentationData.map((item, i) => (
                        <div className="col-lg-6 col-md-6 col-6" key={i}>
                          <div className="presentation-box-outer">
                            <div className={item.clsname ? 'presentation-box-inner ' + item.clsname : 'presentation-box-inner presentation-box-inner-1'} >
                              <img src={item.image ? item.image : ''} alt="" />
                            </div>
                          </div>
                        </div>
                      ))
                      : ''}



                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="wide-cta">
                        <div className="wide-cta-cont">
                          <Link to="/contact/" className="wide-cta-banner-an">Like what you see? Let’s talk!</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <section className="Presentnt-main sec-Presentnt-3 common-sec">
            <div className="inner-container-box">
              <div className="sec-Presentnt-3-gradient-1 sec-gradient"></div>
              <div className="sec-Presentnt-3-gradient-2 sec-gradient"></div>
              <div className="shapes sec-Presentnt-3-hexa-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="hex-cls-cs-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="shapes sec-Presentnt-3-hexa-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">

                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="hex-cls-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="shapes sec-Presentnt-3-rombus-1"></div>
              <div className="shapes sec-Presentnt-3-triangle-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">

                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="what-new-9-3" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="shapes sec-Presentnt-3-rombus-2"></div>
              <div className="shapes sec-Presentnt-3-circle-1"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="presentation-cntnt-box presentation-cntnt-box-2">
                      <div className="sec-head mb-100">
                        <h2>{frontData.serviceHeading ? ReactHtmlParser(frontData.serviceHeading) : ''}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="presentation-row-3-out">
                      <div className="presentation-row-3">


                        {creativeData ?
                          creativeData.map((item, i) => (

                            <div className="presentation-3-box" key={i}>
                              <div className="presentation-3-box-in">
                                <div className="icon-box-in">
                                  <img src={item.image ? item.image : ''} alt="" />
                                </div>
                                <div className="cntnt-box-in">
                                  <h5>{item.heading ? item.heading : ''} </h5>
                                  <p>{item.description ? item.description : ''}</p>
                                </div>
                              </div>
                            </div>

                          ))
                          : ''}





                      </div>

                      <div className="presentation-cta">
                        <div className="fliper-cta-box text-center">

                          <div className="fliper-cta">
                            <Link to="/contact/" className="fliper-cta-an">get started</Link>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">

                    <div className="presentation-cntnt-box presentation-cntnt-box-2">
                      <div className="sec-head mb-100">
                        <h2>Our Work Before & After</h2>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">

                    <Slider key={sliderData.id ? sliderData.id : ''} workslider={sliderData ? sliderData : ''} />
















                  </div >
                </div >
















              </div >

            </div >
          </section >


          < section className="lets-talk common-sec" >

            <div className="inner-container-box">

              <div className="shapes lets-talk-triangle-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">

                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Floor">
                      <polygon className="cls-9-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="star-shape lets-talk-star"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="sec-head">
                      <h2>Accelerate Your Creative Execution<br />with Impeccable Design and
                                Perfection<br />Every time</h2>


                    </div>
                  </div>
                </div>
                <div className="row">




                  <div className="col-sm-12 text-center">
                    <div className="email-snd-area">
                      <input type="email" className="form-control inpt-snd-email" placeholder="Business Email " />




                      <div className="fliper-cta-box text-center m-20">

                        <div className="fliper-cta">


                          <button className="fliper-cta-an" type="submit">let's talk</button>

                        </div>


                      </div>


                    </div>

                  </div>





                </div>
              </div>

            </div>

          </section>



        </div >

        <Footer />
      </div >
    </>

  )
}
