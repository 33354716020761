import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Service(props) {
   const frData = props.frontData;
   return (
      <>
         <section className="service-technology common-sec">
            <div className="inner-container-box">

               <div className="service-inner-box">

               <div className="service-gradient-new service-gradient-new-1"></div>
               <div className="service-gradient-new service-gradient-new-2"></div>
               <div className="service-gradient-new service-gradient-new-3"></div>
               <div className="shapes service-circle-2"></div>
               <div className="shapes service-circle-1"></div>  
               <div className="star-1 star-service-new"></div>
                <div className="shapes service-triangle-1-new"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25"><g id="Layer_2" data-name="Layer 2"><g id="Floor"><polygon className="cls-cs-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon></g></g></svg></div> 
                  <div className="shapes service-rombus-1"></div>
                  
                 
                  <div className="shapes service-hexa-2">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="hex-cls-1"
                                 points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="shapes service-hexa-3">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="hex-cls-1"
                                 points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77" />
                           </g>
                        </g>
                     </svg>
                  </div>
                 
               </div>
               <div className="service-technology-inner">
                  <div className="service-section-top">
                     <div className="container">
                        <div className="row">
                           <div className="col-lg-12">
                              <div className="sec-head">
                                 <h2>{frData.serviceHeading ? ReactHtmlParser(frData.serviceHeading) : ''}</h2>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-12">
                              <div className="services-content text-center">
                                 <span className="cta capsule-cta cta-serv"> design</span>
                                 <p>{frData.servicdescription ? ReactHtmlParser(frData.servicdescription) : ''}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="service-grid-container">
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/presentation/"><img src={frData.presentatimg ? frData.presentatimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/presentation/">{frData.presentationheading ? ReactHtmlParser(frData.presentationheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/print-and-packaging/"><img src={frData.packagingimg ? frData.packagingimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5> <Link to="/work/print-and-packaging/">{frData.packagingheading ? ReactHtmlParser(frData.packagingheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to=""><img src={frData.landingimg ? frData.landingimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5> <Link to="">{frData.landingheading ? ReactHtmlParser(frData.landingheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/video"><img src={frData.videoimg ? frData.videoimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5> <Link to="/work/video">{frData.videoheading ? ReactHtmlParser(frData.videoheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/print-and-packaging/"><img src={frData.printimg ? frData.printimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/print-and-packaging/">{frData.printheading ? ReactHtmlParser(frData.printheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/mobile-designs/"><img src={frData.mobileimg ? frData.mobileimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/mobile-designs/">{frData.mobileheading ? ReactHtmlParser(frData.mobileheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/digital-ads-banners/"><img src={frData.digitalimg ? frData.digitalimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/digital-ads-banners/">{frData.digitalheading ? ReactHtmlParser(frData.digitalheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/design/"><img src={frData.websiteimg ? frData.websiteimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/design/">{frData.websiteheading ? ReactHtmlParser(frData.websiteheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <Link to="/work/infographics/"><img src={frData.infographicimg ? frData.infographicimg : ''} alt="" /></Link>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><Link to="/work/infographics/">{frData.infographicheading ? ReactHtmlParser(frData.infographicheading) : ''}</Link></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="technology-section-top">
                     <div className="container">
                        <div className="row">
                           <div className="col-lg-12">
                              <div className="services-content text-center">
                                 <span className="cta capsule-cta cta-tech"> {frData.techHeading ? ReactHtmlParser(frData.techHeading) : ''}</span>
                                 <p>{frData.techdescription ? ReactHtmlParser(frData.techdescription) : ''}                              </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="service-grid-container">
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <a href=""><img src={frData.maketingimg ? frData.maketingimg : ''} alt="" /></a>
                              </div>
                              <div className="sevice-title-box">
                                 <h5> <a href="">{frData.maketingheading ? ReactHtmlParser(frData.maketingheading) : ''}</a></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <a href=""><img src={frData.webtechimg ? frData.webtechimg : ''} alt="" /></a>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><a href="">{frData.webtechheading ? ReactHtmlParser(frData.webtechheading) : ''}</a></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="service-grid-out">
                        <div className="service-grid-in">
                           <div className="srvice-grid-box">
                              <div className="sevice-img-box">
                                 <a href=""><img src={frData.appdevimg ? frData.appdevimg : ''} alt="" /></a>
                              </div>
                              <div className="sevice-title-box">
                                 <h5><a href="">{frData.appdevheading ? ReactHtmlParser(frData.appdevheading) : ''}</a></h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12">
                           <div className="fliper-cta-box text-center">
                              <div className="fliper-cta">
                                 <a href="" className="fliper-cta-an">Start now</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}
