import React from 'react';
import { Helmet } from 'react-helmet';

export default function GlobalHelmet(props) {
   
  return (
    <div>
      <Helmet>
      <title>{props.metaValue.metatitle?props.metaValue.metatitle:''} </title> 
      <meta name="description" content={props.metaValue.metadescription?props.metaValue.metadescription:''} />
      <meta name="author" content={props.metaValue.author?props.metaValue.author:''}  />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keyword" content={props.metaValue.metakeyword?props.metaValue.metakeyword:''}  />
    </Helmet>
    </div>
  )
}

