import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function Design() {
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const [frontData, setfrontData] = useState(false);
    const [creativeData, setcreativeData] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true);
        RestService.getwebDesigndata(function (res) {
            const response = JSON.parse(res);
            //  console.log(response);          
            if (response.data) {
                setmetaData(response.data.data.metaData);
                setfrontData(response.data.data.frontData);
                setcreativeData(response.data.data.creativeData);
                setmetaData(response.data.data.metaData);

            }
            setLoading(false);
        });

    }, []);
    return (
        <>
            {loading ? <Loader /> : ''}
            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <div className="main-container">
                <Header />
                <div class="inner-pages">
                    <div class="inner-container-box">
                        <div class="blog-page-grad about-page-grad-1"></div>
                        <div class="blog-page-grad about-page-grad-2"></div>
                        <div class="blog-page-grad about-page-grad-3"></div>
                        <div class="shapes about-p-circle-1"></div>
                        <div class="shapes about-p-rombus-1"></div>
                        <div class="shapes about-p-star"></div>
                        <div class="shapes about-p-triangle-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Floor">
                                        <polygon class="cls-blog-svg-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <section class="bread-crumb">
                            <div class="container">
                                <div class="bread-cum">
                                    <ol class="cd-breadcrumb">
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">Design</a></li>
                                        <li><a href="#">Website Design</a></li>
                                    </ol>
                                </div>
                            </div>
                        </section>
                        <section class="inner-content-page sec-inner-page">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-12">
                                        <div class="presentation-img-box presentation-img-box-1">
                                            <img src={frontData.leftimg ? frontData.leftimg : ''} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7 col-12">
                                        <div class="presentation-cntnt-box presentation-cntnt-box-1">
                                            <div class="sec-head mb-40">
                                                <h2>{frontData.heading ? ReactHtmlParser(frontData.heading) : ''}</h2>
                                            </div>
                                            <p> {frontData.description ? ReactHtmlParser(frontData.description) : ''}</p>
                                            <div class="fliper-cta-box">
                                                <div class="fliper-cta">
                                                    <Link to="/contact/" class="fliper-cta-an">Talk to us</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="sec-head">
                                            <h2>{frontData.superaffordable ? ReactHtmlParser(frontData.superaffordable) : ''}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="inner-page-content-box">
                                            <h5>{frontData.newheading ? ReactHtmlParser(frontData.newheading) : ''}</h5>
                                            <p> {frontData.newdescription ? ReactHtmlParser(frontData.newdescription) : ''}</p>
                                            <div class="inner-page-cta">
                                                <div class="inner-page-box text-center">
                                                    <div class="inner-cta">
                                                        <Link to="/contact/" class="inner-cta-an">get started</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="inner-page-content-box">
                                            <h5>{frontData.tiredheading ? ReactHtmlParser(frontData.tiredheading) : ''}</h5>
                                            <p> {frontData.tireddescription ? ReactHtmlParser(frontData.tireddescription) : ''}</p>
                                            <div class="inner-page-cta">
                                                <div class="inner-page-box text-center">
                                                    <div class="inner-cta">
                                                        <Link to="/contact/" class="inner-cta-an">get started</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="inner-content-img">
                                            <img src={frontData.midimg ? frontData.midimg : ''} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="inner-page-app-dev common-sec">
                            <div class="inner-container-box">
                                <div class="sec-Presentnt-3-gradient-1 sec-gradient"></div>
                                <div class="sec-Presentnt-3-gradient-2 sec-gradient"></div>
                                <div class="shapes sec-Presentnt-3-hexa-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon class="hex-cls-cs-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="shapes sec-Presentnt-3-hexa-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon class="hex-cls-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="shapes sec-Presentnt-3-rombus-1"></div>
                                <div class="shapes sec-Presentnt-3-triangle-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon class="what-new-9-3" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="shapes sec-Presentnt-3-rombus-2"></div>
                                <div class="shapes sec-Presentnt-3-circle-1"></div>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="presentation-cntnt-box presentation-cntnt-box-2">
                                                <div class="sec-head mb-80">
                                                    <h2>{frontData.serviceHeading ? ReactHtmlParser(frontData.serviceHeading) : ''}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="presentation-row-3-out">
                                                <div class="presentation-row-3">

                                                    {creativeData ?
                                                        creativeData.map((item, i) => (

                                                            <div className="presentation-3-box" key={i}>
                                                                <div className="presentation-3-box-in">
                                                                    <div className="icon-box-in">
                                                                        <img src={item.image ? item.image : ''} alt="" />
                                                                    </div>
                                                                    <div className="cntnt-box-in">
                                                                        <h5>{item.heading ? item.heading : ''} </h5>
                                                                        <p>{item.description ? item.description : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))
                                                        : ''}

                                                </div>
                                                <div class="presentation-cta">
                                                    <div class="fliper-cta-box text-center">
                                                        <div class="fliper-cta">
                                                            <Link to="/contact/" class="fliper-cta-an">get started</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                <Footer />
            </div>

        </>
    )
}
