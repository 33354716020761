import React from 'react';
import Slider from "react-slick";



export default class WorkSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShow: true,
        };
    }
    componentDidMount() {

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1500,
            slidesToShow: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
        };
        return (
            <>
                <div className="slick-after-before slick-initialized slick-slider slick-dotted">
                    {this.props.workslider ? <>
                        <Slider {...settings} >
                            {this.props.workslider.map((slideval, i) => (
                                <div className="after-before-outer-box" key={i}>
                                    <div className="after-before-work">
                                        <div className="after-before-left after-before-left1">
                                            <div className="after-before-caption">
                                                <h3>b <br /> e<br />f<br />o<br />r<br />e</h3>
                                            </div>
                                            <div className="before-img">
                                                <img src={slideval.bimage ? slideval.bimage : ''} alt="" />
                                            </div>
                                        </div>
                                        <div className="after-before-right after-before-left2">
                                            <div className="after-before-caption">
                                                <h3>a<br />f<br />t<br />e<br />r</h3>
                                            </div>
                                            <div className="after-img">
                                                <img src={slideval.aimage ? slideval.aimage : ''} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ))}
                        </Slider>
                    </> : ''}
                </div>
            </>
        )
    }
}
