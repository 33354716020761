import React from 'react'

export default function Service(props) {
   console.log(props.serviceValue)
   let temp = 1;
  return (
    <>
    <section className="services">
            <h2>SERVICES</h2>
            {!props.serviceValue? '' :
         <> <ul>
              {props.serviceValue.map((values,index) => (
                  <>
                 <li>
                    <div className="img"> <img src={values.image} alt=""  width="48"  height="42" /> </div>
                    <article>
                       <h3> {values.heading}  </h3>
                       <p> {values.description  } 
                       </p>  
                    </article>
                 </li>
                 { (temp++ % 2) == 0 ? <li className="li-divider"> </li>:'' }
              </>
               ))}
               </ul> 
        </>

}
           
            
             
         </section>
    </>
  )
}
