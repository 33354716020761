import React from 'react';
import { Link } from 'react-router-dom';

export default function Banner2(props) {

   const frData = props.frontData;
   return (
      <>
         <div className="banner-section">
            <div className="inner-container-box inner-container-box-banner">
               <div className="bg-grad hm-new-bg-1"></div>
               <div className="bg-grad hm-new-bg-2"></div>
               <div className="bnnr-shapes bnner-circle-1"></div>
               <div className="bnnr-shapes bnner-circle-2"></div>
               <div className="bnnr-shapes bnner-circle-3"></div>
              
               <div className="bnnr-shapes bnner-triangle-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                     <g id="Layer_2" data-name="Layer 2">
                        <g id="Floor">
                           <polygon className="cls-1-15" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                        </g>
                     </g>
                  </svg>
               </div>
               <div className="bnnr-shapes bnner-triangle-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                     <g id="Layer_2" data-name="Layer 2">
                        <g id="Floor">
                           <polygon className="cls-1-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                        </g>
                     </g>
                  </svg>
               </div>
               <div className="bnnr-shapes bnner-rombus-1"></div>
               <div className="bnnr-shapes bnner-rombus-2"></div>
               <div className="star-1 star-bnnr"></div>
            </div>
            <div className="banner-overlay">
              
               <div className="heading-banner banner-head-opt2">
                  <img src={require('../../images/resources/banner-heading-2.png').default} alt="" />
               </div>
               <div className="sub-head-bnnr">
                  <h4><span>2.2x Speed. </span><span>0% Hassle. </span><span>1/4 Cost.</span></h4>
               </div>

               <div class="fliper-cta-box text-center cta-bnnr-new">
                  <div class="fliper-cta">
                     <Link to="/contact/" className="cta-bnnr-custom cta-custom">Book a Discovery Call Now</Link>
                     </div>
                     </div>

              
            </div>
         </div>

      </>
   )
}
