import React from 'react';
import { Link } from 'react-router-dom';

export default function Box(props) { 
    const pagename = props.pagename;
    const smapletxt = props.sampleTxt?props.sampleTxt:'Samples »';
    return ( 
       
                <div className="box">
                	<img src={props.value.image} className="img" />
                    <div className="box_txt">
                    	<div className="blue_text">
                        	<span>{props.value.name}</span>
                        </div>
                        <div className="yellow_text">
                        	<Link to={pagename+props.value.slug}>{smapletxt} </Link>
                        </div>
                    
                    </div>
                </div> 
    )
}
