import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../../GlobalHelmet';
import Header from './../../layout/Header';
import Footer from './../../layout/Footer';
import Tabs from './../../component/Tablist';
import { Link,useParams } from 'react-router-dom';
import Boxlist from './../../component/Box';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function Viewprojects() {
    const   userParams = useParams();
    const projectslug = userParams.projectslug; 
    const [projectlist, seprojectlist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});

    useEffect(() => {
        setLoading(true);
        RestService.getPorjectList(function (res) {
            const response = JSON.parse(res);
             
            if (response.data) {
                setmetaData(response.data.data.metaData);
                seprojectlist(response.data.data.projectlist);
               // console.log(response.data.data.projectlist); 
                console.log(projectlist);
            }
        },projectslug);

    }, []);
    return (
        <>
        
            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <section id="mainwrap">
                <Header />
                <section class="innerpageTitle black">
                    <h2>Work / <span>Design</span></h2>
                </section>
                <Tabs  value="design" />
                {!projectlist? <Loader /> :
        <>
                <div className="work_middle">
                  <div className="tabs_content">
                     <div className="workflow">
                        <h1> {metaData.heading?metaData.heading:''} <span>{metaData.remheading?metaData.remheading:''}</span> </h1>
                        <Link to="/work/design" class="back_page"> BACK TO PROJECTS</Link>
                        <div class="clr"></div>
                        <p>{metaData.paragraph?metaData.paragraph:''}</p>
                         
                        
                     </div>
                     <div className="clr"></div>
                      
                       {projectlist ?projectlist.map(item => (
                        <Boxlist value={item} pagename={'/work/view-projects/'+projectslug+'/' }  />
                        )) : ''}
                  </div>
               </div>
               </>
        }
                <Footer />
            </section>
        
        </>
    )
}
