import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../GlobalHelmet';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import RestService from './../RestService';
import Loader from './../component/Loader';
import Tabs from './../component/Tablist';

import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [metaData, setmetaData] = useState({});
  const [headDetails, setheadDetails] = useState(false);
  const [mediaData, setmediaData] = useState(false);
  const [infoData, setinfoData] = useState(false);
  const [symantData, setsymantData] = useState(false);
  const [acoData, setacoData] = useState(false);

  useEffect(() => {
    setLoading(true);
    RestService.getproductionList(function (res) {
      const response = JSON.parse(res);
      //  console.log(response);          
      if (response.data) {
        let heading = response.data.data.heading;
        let description = response.data.data.description; 
        let hdedata = { 'heading': heading, 'description': description, };
        setmetaData(response.data.data.metaData);
        setmediaData(response.data.data.mediaData);
        setinfoData(response.data.data.infoData);
        setsymantData(response.data.data.symantData);
        setacoData(response.data.data.acoData);
        setheadDetails(hdedata);
        console.log(headDetails);
      }
    });

  }, []);
  return (
    <>

      <GlobalHelmet metaValue={metaData ? metaData : ''} />

      <section id="mainwrap">
        <Header />
        <section class="innerpageTitle black">
          <h2>Work / <span>Marketing Automation</span></h2>
        </section>
        <Tabs value="production" />
        <section id="work_section"> 
        {!mediaData ? <Loader /> :
          <>
          <section id="production"> 
            <div class="clr"></div>
            <div class="work_middle">
                <div class="tabs_content">
                  <div class="workflow">
                      <h2> {headDetails.heading ? ReactHtmlParser(headDetails.heading) : ''}</h2>
                      <p>{headDetails.description ? headDetails.description : ''}</p>
                      <div class="production_page" >
                        <h2>Medidata</h2>
                        <div class="clr"></div>
                        <div class="production_page_left">
                            <p> {mediaData.description?mediaData.description:''} </p>
                        </div>
                        <div class="production_page_right">
                        <p> {mediaData.list?ReactHtmlParser(mediaData.list):''} </p>
                        </div>
                        <div class="clr"></div>
                      </div>
                  </div>
                </div>
            </div>
          </section>

          <div class="img-100"><img src={mediaData.image?mediaData.image:''} /></div>
          <section id="production">
            <div class="tabs_content">
                <div class="production_page">
                  <div class="work_middle">
                      <h2>Informatica</h2>
                      <div class="clr"></div>
                      <div class="production_page_left">
                            <p> {infoData.description?infoData.description:''} </p>
                        </div>
                      <div class="production_page_right">
                      {infoData.list?ReactHtmlParser(infoData.list):''} 
                      </div>
                      <div class="clr"></div>
                  </div>
                </div>
            </div>
          </section>
          <div class="img-100"><img src={infoData.image?infoData.image:''} /></div>
          <section id="production">
            <div class="tabs_content">
                <div class="production_page">
                  <div class="work_middle">
                      <h2>Symantec</h2>
                      <div class="clr"></div>
                      <div class="production_page_left">
                            <p> {symantData.description?symantData.description:''} </p>
                        </div>
                      <div class="production_page_right">
                      {symantData.list?ReactHtmlParser(symantData.list):''} 
                      </div>
                      <div class="clr"></div>
                  </div>
                </div>
            </div>
          </section>

          <div class="img-100"><img src={symantData.image?symantData.image:''} /></div>
          <section id="production">
            <div class="tabs_content">
                <div class="production_page">
                  <div class="work_middle">
                      <h2>Acco Brands</h2>
                      <div class="clr"></div>
                      <div class="production_page_left">
                            <p> {acoData.description?acoData.description:''} </p>
                        </div>
                      <div class="production_page_right">
                      {acoData.list?ReactHtmlParser(acoData.list):''} 
                      </div>
                      <div class="clr"></div>
                  </div>
                </div>
            </div>
          </section>
          <div class="img-100"><img src={acoData.image?acoData.image:''} /></div>
          <div class="midbutton2">
            <Link to="/contact"> LET'S TALK BUSINESS. CONTACT US &raquo;</Link>
          </div>
             
          </>
        }
         </section>
        <Footer />
      </section>

    </>
  )
}
