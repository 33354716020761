import React ,{useState,useEffect} from 'react';
import GlobalHelmet from './../../GlobalHelmet';
import Header  from './../../layout/Header';
import Footer  from './../../layout/Footer' ;
import Tech from './TechData'; 
import RestService from './../../RestService';
import Loader from './../../component/Loader';  
import Tabs from './../../component/Tablist'; 
 
export default function Index() {
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const color = '#E93F33';  
    const [techlistdata,settechlistdata] = useState(false);

    useEffect(() => {
        setLoading(true);
        RestService.getTechList(function(res){  
              const response  =JSON.parse(res) ;  
            //  console.log(response);          
              if(response.data){
                setmetaData(response.data.data.metaData); 
                settechlistdata(response.data.data.techlist)
              }               
        });   

      }, []);
    return (
        <>
        
        <GlobalHelmet metaValue={metaData?metaData:''}   />
        <section id="mainwrap"> 
            <Header    /> 

            <section className="innerpageTitle black">
                <h2>Work / <span>Design</span></h2>
            </section>

            <section id="work_section">
               <Tabs value="technology" />
               <div className="clr"></div>

            {!techlistdata? <Loader /> :
            <>
            <Tech data={techlistdata} metavalue={metaData} />  
            </>
           }
           </section>
            <Footer    />
        </section>
        
        </>
    )
}
