import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../GlobalHelmet';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import RestService from './../RestService';
import Loader from './../component/Loader';
import Tabs from './../component/Tablist';
import './marketting.css';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [metaData, setmetaData] = useState({});
  const [headDetails, setheadDetails] = useState(false);
  const [mediaData, setmediaData] = useState(false);
  const [clientData, setclientData] = useState(false);
  const [partnerData, setpartnerData] = useState(false);

  useEffect(() => {
    setLoading(true);
    RestService.getmarketList(function (res) {
      const response = JSON.parse(res);
      //  console.log(response);          
      if (response.data) {
        let heading = response.data.data.heading;
        let description = response.data.data.description;
        let clientheading = response.data.data.clientheading;
        let partnerheading = response.data.data.partnerheading;
        let hdedata = { 'heading': heading, 'description': description, 'partnerheading': partnerheading, 'clientheading': clientheading };
        setmetaData(response.data.data.metaData);
        setmediaData(response.data.data.mediaData);
        setclientData(response.data.data.clientData);
        setpartnerData(response.data.data.partnerData);
        setheadDetails(hdedata);
        console.log(headDetails);
      }
    });

  }, []);
  return (
    <>

      <GlobalHelmet metaValue={metaData ? metaData : ''} />

      <section id="mainwrap">
        <Header />
        <section class="innerpageTitle black">
          <h2>Work / <span>Marketing Automation</span></h2>
        </section>
        <Tabs value="marketting" />
        <section id="work_section">
        {!mediaData ? <Loader /> :
          <>

            <section  >


              <div class="clr"></div>
              <div class="work_middle">
                <div class="tabs_content">

                  <div class="workflow"> <h2>  {headDetails.heading ? ReactHtmlParser(headDetails.heading) : ''} </h2>
                    <p>{headDetails.description ? headDetails.description : ''} </p>
                  </div>
                  <br />
                  <br />
                  <div class="clr"></div>

                  {mediaData ? mediaData.map(item => (
                    <div class="box icon_box">
                      <img src={item.image} class="img" />
                      <div class="box_txt">
                        <p>{item.heading}</p>
                      </div>
                    </div>

                  )) : ''}


                  <div class="clr"></div>

                  <div class="workflow"> <h2>{headDetails.clientheading ? headDetails.clientheading : ''}</h2></div>
                  <div class="row updatedBox">

                    {clientData ? clientData.map(item => (
                      <div class="col3">

                        <div class="section-3boxNew">
                          <div class="midleBox"><img src={item.image} /></div>
                        </div>
                      </div>
                    )) : ''}


                  </div>

                  <div class="clr"></div>
                </div></div></section>
            <div class="clr"></div>
            <div class="clr"></div>
            <section id="major-platforms">
              <div class="workflow"><h2>&nbsp;</h2>
                <h2>{headDetails.partnerheading ? headDetails.partnerheading : ''}</h2>
              </div>
              <div class="row updatedBox">

                {partnerData ? partnerData.map(item => (
                  <div class="col3">

                    <div class="section-3boxNew">
                      <div class="midleBox"><img src={item.image} /></div>
                    </div>
                  </div>
                )) : ''}



              </div>
              <div class="clr"></div>
              <div class="midbutton2 development"  >
                <Link to="/contact">LET'S TALK BUSINESS »</Link>
              </div>
            </section>
          </>
        }
        </section>
        <Footer />
      </section>

    </>
  )
}
