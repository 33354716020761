import React, { useState, useEffect } from 'react';
import GlobalHelmet from './../../GlobalHelmet';
import Header from './../../layout/Header';
import Footer from './../../layout/Footer';
import Tabs from './../../component/Tablist';
import { Link, useParams } from 'react-router-dom';
import RestService from './../../RestService';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Loader from './../../component/Loader';
import NextPre from './../../component/NextPre';
import ScrollToTop from './../../component/ScrollToTop';


export default function TechDetails() {
    const userParams = useParams();
    const projectslug = userParams.techslug;
    const designslug = userParams.pageslug;
    const [techlist, settechlist] = useState(true);
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState(false);
    const [mediaData, setmediaData] = useState(false);
    const [param, setparam]         = React.useState(false);
    if(userParams !=param ){
        setparam(userParams);
    }

    useEffect(() => {
        setLoading(true);
        RestService.getTechDetails(function (res) {
            const response = JSON.parse(res);
            let itemdata = '';
            console.log(response.data);
            if (response.data) {
                setmetaData(response.data.data.metaData);
                settechlist(response.data.data.techlist);
                const projectlist = response.data.data.techlist;
                if(projectlist){
                    projectlist.map(item => {
                        if (item.mediatype == 1) {
                            itemdata += '  <video id="example_video_1" className="video-js vjs-default-skin" controls preload="none" width="1180" height="602"  poster=""  data-setup=""><source src="' + item.baseurl + item.media + '" type=\'video/mp4\' /></video>';
                        } else {
                            itemdata += '<img src="' + item.baseurl + item.media + '" />';
                        }
                    });
                }
                setmediaData(itemdata);
                // console.log(response.data.data.projectlist); 
                console.log(techlist);
            }
        }, projectslug, designslug);

    }, [param]);

    return (
        <>
            
                    <GlobalHelmet metaValue={metaData ? metaData : ''} />
                    <section id="mainwrap">
                        <Header />
                        <section className="innerpageTitle black">
                            <h2>Work / <span>Design</span></h2>
                        </section>
                        <Tabs  value="technology" />
                        <NextPre backlink={'/work/development/'+projectslug} nextlink={metaData.nextLink?'/work/development/'+projectslug+'/'+metaData.nextLink:''} prelink={metaData.preLink?'/work/development/'+projectslug+'/'+metaData.preLink:'#'} />
                    {!techlist ? <Loader /> :
                         <>
                        <div className="work_middle">
                            <div className="tabs_content">
                                <h1>{metaData.heading ? metaData.heading : ''} </h1>
                                <div className="clr"></div>
                                {metaData.paragraph ? ReactHtmlParser(metaData.paragraph) : ''}
                                <div className="clr"></div>
                            </div>
                            <div className="collateral_img">
                                {mediaData ? ReactHtmlParser(mediaData) : ''}
                            </div>
                        </div>
                        <div className="clr"></div>
                                    <ScrollToTop /> 
                        </>
                    }
                        <Footer />
                    </section>
               
        </>
    )
}
