import React from 'react';
import './../../bootstrap.min.css'; 
import './../../Stylesheet.css';
import './../../NewStyle.css';
import './../../NewResStyle.css';
import FixedNav from './FixedNav';
import $ from 'jquery';
import {Link} from 'react-router-dom';


export default class innerHeader extends React.Component  { 
        componentDidMount() {
           $('.menu-icon').on('click', 'a', function (e) {
              e.preventDefault();
              $('.full-navigation').addClass('active');
              $('.fixed-navigation').addClass('active-index');
              setTimeout(function () {
                $('.full-menu').addClass('open');
                $('.fixed-navigation').addClass('active-main');
              }, 500)
            });
            
            $('.menu-close').on('click', function (e) {
              e.preventDefault();
              $('.fixed-navigation').removeClass('active-index');
              $('.fixed-navigation').removeClass('active-main');
              $('.full-navigation').removeClass('active');
              $('.full-menu').removeClass('open');
            });
  
            $(document).ready(function () {
  
              $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll >= 110) {
                 $(".main-header").addClass("darkHeader");
                    
                } else {
                 $(".main-header").removeClass("darkHeader");
                   
                }
              });
              
              });
        }
  render(){ 
    return (
        <>
        <header className="inner-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-8 logo-grid">
                        <div className="tangence-logo"> 
                                <Link to="/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.89 9.31">
                                        <defs> 
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-1-in-logo" d="M2.55,2.19H0v-2H7.45v2H4.9v7H2.55Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M11.26.05h.19l4.24,9.11H13.26L13,8.43H9.62l-.31.73H7Zm1.11,6.8L12,6c-.11-.23-.2-.46-.28-.68l-.21-.61c-.07-.21-.13-.41-.19-.61l-.17.61-.21.61c-.08.22-.17.45-.27.68l-.4.9Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M20.26,5.67c-.17-.15-.33-.3-.48-.46l-.41-.43L19,4.33c0,.23.05.45.09.67s0,.4.06.62,0,.42,0,.61V9.16H17V0h.19l4.15,3.59a5.78,5.78,0,0,1,.5.48c.15.17.29.31.42.44l.38.44c0-.23-.05-.45-.07-.67a5.77,5.77,0,0,1,0-.62c0-.22,0-.42,0-.62V.16h2.15V9.3h-.19Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M30.9,9.31A5.84,5.84,0,0,1,28.83,9a4.26,4.26,0,0,1-1.56-1,4.15,4.15,0,0,1-1-1.47,5,5,0,0,1-.34-1.87,4.7,4.7,0,0,1,.37-1.9,4.45,4.45,0,0,1,1-1.48,4.65,4.65,0,0,1,1.5-.94A5,5,0,0,1,30.7,0a4.78,4.78,0,0,1,2.15.41,4.68,4.68,0,0,1,1.3.94L32.65,2.9a3,3,0,0,0-.72-.57,2.45,2.45,0,0,0-1.23-.26,2.25,2.25,0,0,0-1,.21,2.18,2.18,0,0,0-.75.55,2.25,2.25,0,0,0-.45.82,3,3,0,0,0-.16,1,3.83,3.83,0,0,0,.13,1,2.36,2.36,0,0,0,.43.85,2,2,0,0,0,.79.57,2.91,2.91,0,0,0,1.21.22,3.77,3.77,0,0,0,.76-.07,3.52,3.52,0,0,0,.63-.17v-1H30.47V4.17h4V8.42A5.88,5.88,0,0,1,33.08,9,7.13,7.13,0,0,1,30.9,9.31Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M35.73.16h6.4v2H38.08v2h3.38V6H38.08v1.1H42.3v2H35.73Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M43.62,9.3h-.19V.16h2.15V3.07c0,.2,0,.4,0,.62s0,.43-.06.62,0,.44-.07.67c.13-.15.26-.29.38-.44s.27-.27.42-.44a5.78,5.78,0,0,1,.5-.48L50.89,0h.19V9.16H48.93V6.23c0-.19,0-.39,0-.61S49,5.2,49,5s.07-.44.09-.67l-.4.45-.41.43c-.15.16-.31.31-.48.46Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M57.14,9.31a5.43,5.43,0,0,1-2-.35,4.44,4.44,0,0,1-1.5-1,4.34,4.34,0,0,1-.94-1.49,5,5,0,0,1-.33-1.83,4.92,4.92,0,0,1,.34-1.86,4.08,4.08,0,0,1,1-1.47,4.49,4.49,0,0,1,1.49-1A5.1,5.1,0,0,1,57.07,0a4.75,4.75,0,0,1,2.29.51A4.09,4.09,0,0,1,60.91,2L59.05,3.21a2.63,2.63,0,0,0-.75-.81,2,2,0,0,0-1.23-.33,2.25,2.25,0,0,0-1,.19,2,2,0,0,0-.71.53,2.33,2.33,0,0,0-.45.82,3.21,3.21,0,0,0-.16,1.05,3.07,3.07,0,0,0,.16,1,2.25,2.25,0,0,0,.45.82,2.12,2.12,0,0,0,.74.54,2.17,2.17,0,0,0,1,.2A2.25,2.25,0,0,0,59.29,6l1.9,1.08A3.8,3.8,0,0,1,59.66,8.7,4.85,4.85,0,0,1,57.14,9.31Z" />
                                                <path className="cls-1-in-logo"
                                                    d="M62.32.16h6.4v2h-4v2h3.38V6H64.67v1.1h4.22v2H62.32Z" />
                                            </g>
                                        </g>
                                    </svg>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-1 col-md-1 col-4">
                        <div className="header-nav text-right">
                            <div className="main-menu">
                                <div className="menu-icon">
                                    <a href="#">
                                        <span id="line1"></span>
                                        <span id="line2"></span>
                                        <span id="line3"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="full-navigation"></div>
        <FixedNav />
        </header>

       
        
            
        </>
    )
}
}
