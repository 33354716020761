import React from 'react';
import Slider from "react-slick";





export default class Whatisnew extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         nav1: null,
         nav2: null
      };
   }

   componentDidMount() {
      this.setState({
         nav1: this.slider1,
         nav2: this.slider2
      });
   }

   render() {


      const newSetting = {
         slidesToShow: 1,
         slidesToScroll: 1,
         arrows: false,
         speed: 1200,
         // fade: true,
         asNavFor: '.slidernav',
         infinite: false,
         asNavFor: this.state.nav2,
         ref: slider => (this.slider1 = slider)

      };
      const settings = {
         slidesToShow: 2,
         slidesToScroll: 1,
         asNavFor: this.state.nav1,
         ref: slider => (this.slider2 = slider),
         speed: 1200,
         dots: true,
         infinite: false,
         centerMode: false,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
               }
            }
         ],


         focusOnSelect: true,
         customPaging: function (i) {
            return (
               <a>
                  {'0' + (i + 1)}
               </a>
            );
         }
      };

      return (
         <>
            <section className="h-casestudy common-sec">
               <div className="inner-container-box">
                  <div className="shapes cs-triangle-1">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="cls-cs-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="service-gradient-new cs-gradient-new-1"></div>
                  <div className="shapes cs-hexa-1">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                        <g id="Layer_2" data-name="Layer 2">
                           <g id="Floor">
                              <polygon className="hex-cls-cs-1"
                                 points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77" />
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12">
                           <div className="sec-head mb-60">
                              <h2>case studies</h2>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="container-fluid">
                     <div className="h-casestudy-out-box">
                        <div className="h-casestudy-in-grid in-grid-left">

                           <div className="slider-for">


                              {this.props.casestudy ? <>

                                 <Slider {...newSetting} className="slider-for">
                                    {this.props.casestudy.map((slideval) => (
                                       <div className="slide-container" key={slideval.id}>
                                          <div className="slide-container-in">
                                             <div className="slide-cntnt-box">
                                                <div className="slide-head">
                                                   <h2> {slideval.name ? slideval.name : ''}</h2>
                                                </div>
                                                <div className="slide-head-p">
                                                   <h5>{slideval.description ? slideval.description : ''}</h5>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                    ))}
                                 </Slider>
                              </> : ''}



                           </div>

                        </div>
                        <div className="h-casestudy-in-grid in-grid-mid">
                           <div className="color-slab">
                           </div>
                        </div>
                        <div className="h-casestudy-in-grid in-grid-right">


                           <div className="slidernav">


                              {this.props.casestudy ? <>

                                 <Slider {...settings} className="slidernav">
                                    {this.props.casestudy.map((slideval, i) => (
                                       <div className="slide-btn">
                                          <div className="case-study-img">
                                             <img key={i} src={slideval.image ? slideval.image : ''} alt="" />
                                          </div>
                                       </div>

                                    ))}
                                    <div className="slide-btn">
                                       <div className="case-study-img">

                                       </div>
                                    </div>
                                 </Slider>
                              </> : ''}




                           </div>


                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </>
      )
   }
}
