import React, { Component, useEffect, useState } from 'react';
import Banner from './Banner3';
import Header from './../include/Header';
import Footer from './../include/Footer';
import Oucclients from './Oucclients';
import Advantage from './Advantage';
import Service from './Service';
import Casestudy from './Casestudy';
import Discover from './Discover';
import Whatisnew from './Whatisnew';
import Letstalk from './Letstalk';
import RestService from './../../RestService';
import GlobalHelmet from './../../GlobalHelmet';
import Lodaer from './../../component/Loader';
import './../../slick.css';

function Index3() {
  const color = '#E93F33';
  const [loading, setLoading] = useState(false);
  const [getHomedata, setgetHomedata] = useState({});
  const [frontData, setfrontData] = useState({});
  const [globalCmpny, setglobalCmpny] = useState(false);
  const [whatsnew, setwhatsnew] = useState(false);
  const [casestudy, setcasestudy] = useState(false);

  useEffect(() => {
    setLoading(true);
    RestService.gethomeList(function (res) {
      setLoading(false);
      const response = JSON.parse(res);
      if (response.data.data.metaData) {
        setgetHomedata(response.data.data);
      }
      if (response.data.data.frontData) {
        setfrontData(response.data.data.frontData);
      }
      if (response.data.data.globalCmpny) {
        setglobalCmpny(response.data.data.globalCmpny);
      }
      if (response.data.data.whatsnew) {
        setwhatsnew(response.data.data.whatsnew);
      }
      if (response.data.data.casestudy) {
        setcasestudy(response.data.data.casestudy);
      }

    })
  }, []);

  return (
    <>

      <div className="main-container">
        <Header />
        {!getHomedata ? <Lodaer /> :
          <>
            <GlobalHelmet metaValue={getHomedata.metaData ? getHomedata.metaData : ''} />
            {frontData ? <Banner frontData={frontData} /> : ''}
            {globalCmpny ? <Oucclients key={globalCmpny.id} globalCmpny={globalCmpny} /> : ''}
            {frontData ? <Advantage frontData={frontData} /> : ''}
            {frontData ? <Service frontData={frontData} /> : ''}
            {casestudy ? <Casestudy key={casestudy.id} casestudy={casestudy} /> : ''}
            {frontData ? <Discover frontData={frontData} /> : ''}
            {whatsnew ? <Whatisnew key={whatsnew.id} whatsnew={whatsnew} /> : ''}
            {frontData ? <Letstalk frontData={frontData} /> : ''}
          </>}


        <Footer />


      </div>



    </>
  )
}


export default Index3;