import React, { useState, useEffect } from 'react';
import Header from './../include/innerHeader';
import Footer from './../include/Footer';
import GlobalHelmet from './../../GlobalHelmet';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import RestService from './../../RestService';
import Loader from './../../component/Loader';

export default function Printpackaging() {
    const [loading, setLoading] = useState(false);
    const [metaData, setmetaData] = useState({});
    const [frontData, setfrontData] = useState(false);
    const [creativeData, setcreativeData] = useState(false);
    const [competePrice, setcompetePrice] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true);
        RestService.getPrintpackagingdata(function (res) {
            const response = JSON.parse(res);
            //  console.log(response);          
            if (response.data) {
                setmetaData(response.data.data.metaData);
                setfrontData(response.data.data.frontData);
                setcreativeData(response.data.data.creativeData);
                setmetaData(response.data.data.metaData);
                setcompetePrice(response.data.data.competePrice);

            }
            setLoading(false);
        });

    }, []);
    return (
        <>
            {loading ? <Loader /> : ''}
            <GlobalHelmet metaValue={metaData ? metaData : ''} />
            <div className="main-container">
                <Header />
                <div className="inner-pages">
                    <div className="inner-container-box">
                        <div className="blog-page-grad about-page-grad-1"></div>
                        <div className="blog-page-grad about-page-grad-2"></div>
                        <div className="blog-page-grad about-page-grad-3"></div>
                        <div className="shapes about-p-circle-1"></div>
                        <div className="shapes about-p-rombus-1"></div>
                        <div className="shapes about-p-star"></div>

                        <div className="shapes about-p-triangle-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Floor">
                                        <polygon className="cls-blog-svg-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <section className="bread-crumb">
                            <div className="container">
                                <div className="bread-cum">
                                    <ol className="cd-breadcrumb">
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">Design</a></li>
                                        <li><a href="#">Digital Ads & Banners</a></li>
                                    </ol>
                                </div>
                            </div>
                        </section>

                        <section className="inner-content-page sec-inner-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="presentation-img-box presentation-img-box-1">
                                            <img src={frontData.leftimg ? frontData.leftimg : ''} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="presentation-cntnt-box presentation-cntnt-box-1">
                                            <div className="sec-head mb-40">
                                                <h2>{frontData.heading ? ReactHtmlParser(frontData.heading) : ''}</h2>
                                            </div>
                                            <p>{frontData.description ? ReactHtmlParser(frontData.description) : ''} </p>
                                            <div className="fliper-cta-box">
                                                <div className="fliper-cta">
                                                    <Link to="/contact/" className="fliper-cta-an">Talk to us</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="sec-head">
                                            <h2>{frontData.videoProHeading ? ReactHtmlParser(frontData.videoProHeading) : ''}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {competePrice ?
                                        competePrice.map((item, i) => (

                                            <div className="col-lg-6 col-md-6" key={i}>
                                                <div className="inner-page-content-box">
                                                    <h5> {item.heading ? item.heading : ''}</h5>
                                                    <p> {item.description ? item.description : ''}</p>
                                                    <div className="inner-page-cta">
                                                        <div className="inner-page-box text-center">
                                                            <div className="inner-cta">
                                                                <Link to="/contact/" className="inner-cta-an">get started</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                        : ''}



                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="inner-content-img">
                                            <img src={frontData.midimg ? frontData.midimg : ''} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="inner-page-app-dev common-sec">
                            <div className="inner-container-box">
                                <div className="sec-Presentnt-3-gradient-1 sec-gradient"></div>
                                <div className="sec-Presentnt-3-gradient-2 sec-gradient"></div>
                                <div className="shapes sec-Presentnt-3-hexa-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon className="hex-cls-cs-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="shapes sec-Presentnt-3-hexa-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.23 42.99">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon className="hex-cls-1" points="36.41 31.77 36.41 11.23 18.62 0.96 0.83 11.23 0.83 31.77 18.62 42.04 36.41 31.77"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="shapes sec-Presentnt-3-rombus-1"></div>
                                <div className="shapes sec-Presentnt-3-triangle-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Floor">
                                                <polygon className="what-new-9-3" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="shapes sec-Presentnt-3-rombus-2"></div>
                                <div className="shapes sec-Presentnt-3-circle-1"></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="presentation-cntnt-box presentation-cntnt-box-2">
                                                <div className="sec-head mb-80">
                                                    <h2>{frontData.creativeHeading ? frontData.creativeHeading : ''}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="presentation-row-3-out">
                                                <div className="presentation-row-3">

                                                    {creativeData ?
                                                        creativeData.map((item, i) => (

                                                            <div className="presentation-3-box" key={i}>
                                                                <div className="presentation-3-box-in">
                                                                    <div className="icon-box-in">
                                                                        <img src={item.image ? item.image : ''} alt="" />
                                                                    </div>
                                                                    <div className="cntnt-box-in">
                                                                        <h5>{item.heading ? item.heading : ''} </h5>
                                                                        <p>{item.description ? item.description : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))
                                                        : ''}
                                                </div>
                                                <div className="presentation-cta">
                                                    <div className="fliper-cta-box text-center">
                                                        <div className="fliper-cta">
                                                            <Link to="/contact/" className="fliper-cta-an">get started</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                </div>
                <Footer />
            </div>
        </>
    )
}
