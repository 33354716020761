 import React from 'react'
 import Boxlist from './../../component/Box';
 import { Link } from 'react-router-dom';
 
 
 function TechData(props) { 
    const metavalues = props.metavalue; 
    console.log(props);
    return (
       <>
            
               
               <div className="work_middle">
                  <div className="tabs_content">
                     <div className="workflow">
                        <h2> {metavalues.heading?metavalues.heading:''} <span>{metavalues.remheading?metavalues.remheading:''}</span> </h2>
                        <p>{metavalues.paragraph?metavalues.paragraph:''}</p>
                     </div>
                     <div className="clr"></div>
                     {props.data?props.data.map(item => (
                        <Boxlist value={item} pagename="/work/development/"  />
                        )) : ''}
                        <div className="clr"></div>
                     <div className="midbutton2 development">
                        <Link to="/contact">  LET'S TALK BUSINESS. CONTACT US »</Link>
                     </div>
                  </div>
               </div>
            
       </>
    )
 }
 
 export default TechData
 