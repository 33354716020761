import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Letstalk(props) {
   const frData = props.frontData; 
    return (
        <>
            <section className="lets-talk common-sec">
            <div className="inner-container-box">

            <div className="lets-talk-gradient-new lets-talk-gradient-new-1"></div>
            <div className="shapes lets-talk-triangle-1">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                  <g id="Layer_2" data-name="Layer 2">
                     <g id="Floor">
                        <polygon className="cls-8-1" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                     </g>
                  </g>
               </svg>
            </div>
            
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="sec-head">
                        <h2>{frData.accelerateheading?ReactHtmlParser(frData.accelerateheading):''}
                        </h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12 text-center">
                     <div className="email-snd-area">
                        <input type="email" className="form-control inpt-snd-email" placeholder="Business Email " />
                        <div className="fliper-cta-box text-center m-20">
                           <div className="fliper-cta">
                              <button className="fliper-cta-an" type="submit">let's talk</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </section>
        </>
    )
}
