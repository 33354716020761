import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function AboutData(props) {
   const foundation = props.foundation;
   return (
      <>
         <div className="inner-pages">
            <div className="inner-container-box">
               <div className="blog-page-grad about-page-grad-1"></div>
               <div className="blog-page-grad about-page-grad-2"></div>
               <div className="blog-page-grad about-page-grad-3"></div>
               <div className="blog-page-grad about-page-grad-4"></div>
               <div className="shapes about-p-circle-1"></div>
               <div className="shapes about-p-rombus-1"></div>
               <div className="shapes about-p-star"></div>
               <div className="shapes about-p-triangle-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.71 16.25">
                     <g id="Layer_2" data-name="Layer 2">
                        <g id="Floor">
                           <polygon className="cls-blog-svg-2" points="15.55 1.6 2.25 10.03 17.54 14.87 15.55 1.6" />
                        </g>
                     </g>
                  </svg>
               </div>
               <section className="about-inner-1 sec-inner-page">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                           <div className="sec-head">
                              <h2>{props.heading ? ReactHtmlParser(props.heading) : ''}</h2>
                              <p>   {props.paragraph ? ReactHtmlParser(props.paragraph) : ''} </p>
                              {props.paragraph2 ? ReactHtmlParser(props.paragraph2) : ''}
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="about-inner-2 sec-inner-page">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                           <div className="sec-head">
                              <h2>The Foundation</h2>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                           <div className="about-box-grid">
                              <div className="about-box-col about-box-col-1">
                                 <div className="about-box-icon">
                                    <h3>{foundation.expcounter ? foundation.expcounter : ''}</h3>
                                 </div>
                                 <div className="about-box-head-tag">
                                    <h4> {foundation.expcounter ? foundation.expcounter : ''} <br />{foundation.experheading ? ReactHtmlParser(foundation.experheading) : ''}</h4>
                                 </div>
                              </div>
                              <div className="about-box-col about-box-col-2">
                                 <div className="about-box-icon">
                                    <h3>{foundation.empcounter ? foundation.empcounter : ''}</h3>
                                 </div>
                                 <div className="about-box-head-tag">
                                    <h4>{foundation.empcounter ? foundation.empcounter : ''} {foundation.empheading ? ReactHtmlParser(foundation.empheading) : ''}   </h4>
                                 </div>
                              </div>
                              <div className="about-box-col about-box-col-3">
                                 <div className="about-box-icon">
                                    <img src={require('../../images/icons/icon-3.png').default} alt="" />
                                 </div>
                                 <div className="about-box-head-tag">
                                    <h4>{foundation.experheading ? ReactHtmlParser(foundation.experheading) : ''}</h4>
                                 </div>
                              </div>
                              <div className="about-box-col about-box-col-4">
                                 <div className="about-box-icon">
                                    <img src={require('../../images/icons/icon-4.png').default} alt="" />
                                 </div>
                                 <div className="about-box-head-tag">
                                    <h4>{foundation.divheading ? ReactHtmlParser(foundation.divheading) : ''}</h4>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-12 text-center">
                           <div className="fliper-cta-box text-center mt-60 fliper-cta-all">
                              <div className="fliper-cta fliper-cta-abt">
                                 <Link to="/why-tangence/" className="fliper-cta-an">why tangence</Link>
                              </div>
                           </div>
                           <div className="fliper-cta-box text-center mt-60 fliper-cta-all">
                              <div className="fliper-cta fliper-cta-abt">
                                 <Link to="/work/design" className="fliper-cta-an">view our work</Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </>
   )
}
